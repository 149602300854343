/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { FilterOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty } from 'antd';
import { type RefObject } from 'react';
import type { $MixedElement, AllStatsFilter, AllStatsSavedFilter } from 'types';

type Props = {
    savedFilters: Array<AllStatsSavedFilter>;
    setFilters: (filters: Array<AllStatsFilter>) => void;
    applyButtonRef: RefObject<HTMLButtonElement>;
};

export const AllStatsSavedFilters = ({
    savedFilters,
    setFilters,
    applyButtonRef,
}: Props): $MixedElement => {
    return (
        <Dropdown
            menu={{
                className: 'max-h-[400px] overflow-auto',
                items:
                    savedFilters.length > 0
                        ? savedFilters.map((item, index) => {
                              return {
                                  key: index,
                                  label: (
                                      <Button
                                          type="link"
                                          onClick={() => {
                                              setFilters(item.filters);
                                              applyButtonRef.current?.click();
                                          }}
                                      >
                                          {item.name}
                                      </Button>
                                  ),
                              };
                          })
                        : [
                              {
                                  key: 'empty',
                                  label: (
                                      <Empty description="No saved filters" />
                                  ),
                              },
                          ],
            }}
            trigger={['click']}
        >
            <Button icon={<FilterOutlined />}>Saved Filters</Button>
        </Dropdown>
    );
};
