/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { atom } from 'recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { SetterOrUpdater } from 'recoil';

export const IsSidebarOpenAtom = atom<boolean>({
    default: true,
    key: 'IsSidebarOpen.Atom',
});

export const useIsSidebarOpen = <T = boolean>(): T => {
    return useRecoilValue(IsSidebarOpenAtom) as T;
};

export const useIsSidebarOpenNullSafe = (): boolean => {
    return useIsSidebarOpen<boolean>();
};

export const useSetIsSidebarOpen = (): SetterOrUpdater<boolean> => {
    return useSetRecoilState(IsSidebarOpenAtom);
};
