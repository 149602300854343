/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const formatUnits = (
    value: bigint | string,
    decimals: number,
): number => {
    let display = value.toString();

    const negative = display.startsWith('-');
    if (negative) display = display.slice(1);

    display = display.padStart(decimals, '0');

    // eslint-disable-next-line prefer-const
    let [integer, fraction] = [
        display.slice(0, display.length - decimals),
        display.slice(display.length - decimals),
    ];
    fraction = fraction.replace(/(0+)$/, '');
    const num = `${negative ? '-' : ''}${integer || '0'}${
        fraction ? `.${fraction}` : ''
    }`;
    return parseFloat(num);
};
