/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiGetWaitlistCodes } from 'api';
import type { CustomMutationResult, WaitlistCodesResponseDto } from 'api/types';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type DataType = WaitlistCodesResponseDto;

type Query = {
    page?: number;
};

export const useGetWaitlistCodesMutation = (
    query: Query,
): [
    CustomMutationResult<DataType, Query>,
    DataType,
    Dispatch<SetStateAction<DataType>>,
] => {
    const [waitlistCodes, setWaitlistCodes] =
        useState<WaitlistCodesResponseDto>({
            data: [],
            count: 0,
            next: false,
        });

    const mutation = useMutation({
        mutationFn: async (query: Query) => {
            const response = await apiGetWaitlistCodes({
                page: query.page,
            });
            setWaitlistCodes(response.data);
            return response.data;
        },
    });

    useEffect(() => {
        mutation.mutate(query);
    }, [query.page]);

    return [mutation, waitlistCodes, setWaitlistCodes];
};
