/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'api';
import { Paths } from 'const';
import { authRoutes, unAuthRoutes } from 'const/paths';
import { useInitialAuth } from 'hooks';
import { Auth } from 'pages';
import { useMemo } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { useAuth } from 'store/AuthStore';
import type { $MixedElement } from 'types';
import { permissions } from 'utils';
import { WagmiProvider } from 'wagmi';
import { config } from 'wagmi-config';

function App(): $MixedElement {
    return (
        <GoogleOAuthProvider clientId="350432936582-o6b71l5kso53v3t075q8u8rapa8ki1ul.apps.googleusercontent.com">
            <RecoilRoot>
                <Main />
            </RecoilRoot>
        </GoogleOAuthProvider>
    );
}

function Main(): $MixedElement {
    const auth = useAuth();

    const router = useMemo(() => {
        const extraRoutes = auth?.isAuth
            ? [...authRoutes].filter((r) =>
                  permissions.hasPermission(r.permission),
              )
            : [...unAuthRoutes];

        const router = createBrowserRouter([
            ...extraRoutes,
            {
                path: Paths.AUTH,
                element: <Auth />,
            },
        ]);
        return router;
    }, [auth]);

    useInitialAuth(router);

    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
            </QueryClientProvider>
        </WagmiProvider>
    );
}

export default App;
