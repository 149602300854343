/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ResponsivePie } from '@nivo/pie';
import type { $MixedElement } from 'types';
import type { PieChartData, PieChartProps } from 'utils';

type PieChartComponentProps = {
    data: Array<PieChartData>;
    props: PieChartProps;
};

export const PieChart = ({
    data,
    props,
}: PieChartComponentProps): $MixedElement => (
    <ResponsivePie
        data={data}
        sortByValue={true}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        valueFormat={props.valueFormat}
        innerRadius={0.4}
        padAngle={0.5}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
        }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
        }}
        colors={props.colors}
        legends={props.legends}
    />
);
