/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { SelectProps } from 'antd';

export const PAGE_SIZE = 50;

export const getTagColor = (type: string): string => {
    const colors: Record<string, string> = {
        transfer: 'blue',
        invest: 'green',
        swap: 'purple',
        layerswap: 'cyan',
        guardian_change: 'orange',
        onramp: 'magenta',
        peanut: 'pink',
        referral: 'red',
        unknown: 'default',
    };
    return colors[type] || 'default';
};

export const txTypeOptions: SelectProps['options'] = [
    { value: 'transfer', label: 'Transfers', color: getTagColor('transfer') },
    { value: 'invest', label: 'Investments', color: getTagColor('invest') },
    { value: 'swap', label: 'Swaps', color: getTagColor('swap') },
    { value: 'layerswap', label: 'Layerswap', color: getTagColor('layerswap') },
    {
        value: 'guardian_change',
        label: 'Guardian Changes',
        color: getTagColor('guardian_change'),
    },
    { value: 'onramp', label: 'Onramps', color: getTagColor('onramp') },
    // { value: 'peanut', label: 'Peanut', color: getTagColor('peanut') }, broken
    // { value: 'referral', label: 'Referrals', color: getTagColor('referral') },
];

export const typeToTable = (type: string): string => {
    switch (type) {
        case 'transfer':
            return 'history_token_transfer';
        case 'invest':
            return 'history_invest';
        case 'swap':
            return 'history_swap';
        case 'layerswap':
            return 'history_layerswap';
        case 'guardian_change':
            return 'history_guardian_change';
        case 'onramp':
            return 'history_onramp';
        case 'peanut':
            return 'history_peanut';
        case 'referral':
            return 'history_referral';
        default:
            return '';
    }
};
