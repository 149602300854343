/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiGetWaitlists } from 'api';
import type { CustomMutationResult, WaitlistResponseDto } from 'api/types';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type DataType = WaitlistResponseDto;

type Query = {
    page?: number;
    debouncedSearch?: string;
};

export const useGetWaitlistsMutation = (
    query: Query,
): [
    CustomMutationResult<DataType, Query>,
    DataType,
    Dispatch<SetStateAction<DataType>>,
] => {
    const [waitlists, setWaitlists] = useState<WaitlistResponseDto>({
        data: [],
        count: 0,
        next: false,
    });

    const mutation = useMutation({
        mutationFn: async (query: Query) => {
            const response = await apiGetWaitlists({
                page: query.page,
                search: query.debouncedSearch,
                searchKey: 'email',
            });
            setWaitlists(response.data);
            return response.data;
        },
    });

    useEffect(() => {
        mutation.mutate(query);
    }, [query.page, query.debouncedSearch]);

    return [mutation, waitlists, setWaitlists];
};
