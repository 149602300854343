/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    Button,
    Empty,
    List,
    Pagination,
    Row,
    Spin,
    Table,
    Typography,
} from 'antd';
import { useGetRafflesQuery } from 'api/query/useGetRafflesQuery';
import { Layout, Sidebar } from 'components';
import { CreateRaffleModal } from 'components/modals/CreateRaffleModal';
import { useModalController } from 'hooks/useModalController';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';

export const Raffle = (): $MixedElement => {
    const [page, setPage] = useState(0);
    const { data: response, isPending } = useGetRafflesQuery(page);
    const { data } = response;
    const controller = useModalController();

    const dataSource = useMemo(() => {
        return data.map((raffle) => {
            return {
                id: raffle.id,
                name: raffle.name,
                created_at: raffle.created_at,
                count: raffle.links.length,
                links: raffle.links.map((link) => link.link),
                link: 'https://getclave.io/link/raffle?p=' + raffle.password,
                password: raffle.password,
                index: raffle.id,
                key: raffle.id,
            };
        });
    }, [data]);

    return (
        <Row wrap={false}>
            <CreateRaffleModal modalController={controller} />
            <Sidebar />
            <Layout>
                <Row className="items-center" justify="space-between">
                    <Typography.Title className="mb-0" level={1}>
                        Raffles
                    </Typography.Title>
                    <Button
                        type="primary"
                        onClick={(): void => {
                            controller.open();
                        }}
                    >
                        New Raffle
                    </Button>
                </Row>

                {isPending && (
                    <Spin tip="Loading" size="small">
                        <div className="p-12 bg-gray-100 rounded-sm" />
                    </Spin>
                )}
                {!isPending && (
                    <>
                        {data.length > 0 ? (
                            <>
                                <div className="max-h-[80vh] overflow-auto">
                                    <Table
                                        pagination={false}
                                        dataSource={dataSource}
                                        columns={Columns}
                                    />
                                </div>
                                <Pagination
                                    onChange={(e): void => {
                                        setPage(e - 1);
                                    }}
                                    pageSize={50}
                                    showSizeChanger={false}
                                    className="mt-5 mx-auto"
                                    current={page + 1}
                                    defaultCurrent={1}
                                    total={response.count}
                                />
                            </>
                        ) : (
                            <Empty description="No raffles" />
                        )}
                    </>
                )}
                {isPending}
            </Layout>
        </Row>
    );
};

const Columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Password',
        dataIndex: 'password',
        key: 'password',
    },
    {
        title: 'Copy Link',
        dataIndex: 'link',
        key: 'link',
        render: (link: string): $MixedElement => (
            <a href={link} target="blank" className="text-blue-500">
                {link}
            </a>
        ),
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
    },
    {
        title: 'Links',
        dataIndex: 'links',
        key: 'links',
        render: (links: Array<string>): $MixedElement => {
            return (
                <List
                    className="max-h-[300px] overflow-auto"
                    itemLayout="horizontal"
                    dataSource={links}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                />
            );
        },
    },
];
