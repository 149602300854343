/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { DownOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Dropdown, Typography } from 'antd';
import { type $MixedElement } from 'types';
import { type IUser, UserColumns } from 'utils';
import { columnGroups } from 'utils/dashboardV2/constants';

type Props = {
    checkedList: Array<keyof IUser>;
    setCheckedList: (checkedList: Array<keyof IUser>) => void;
};

export const AllStatsSelectColumns = ({
    checkedList,
    setCheckedList,
}: Props): $MixedElement => {
    return (
        <Col span={6}>
            <Typography.Text strong>Columns:</Typography.Text>
            <Dropdown
                menu={{
                    items: columnGroups.map((group) => ({
                        key: group.title,
                        type: 'group',
                        label: (
                            <div onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                    checked={group.children.every((child) =>
                                        checkedList.includes(
                                            child as keyof IUser,
                                        ),
                                    )}
                                    onChange={(e) => {
                                        const newCheckedList = e.target.checked
                                            ? [
                                                  ...new Set([
                                                      ...checkedList,
                                                      ...group.children,
                                                  ]),
                                              ]
                                            : checkedList.filter(
                                                  (item) =>
                                                      !group.children.includes(
                                                          item,
                                                      ),
                                              );
                                        setCheckedList(
                                            newCheckedList as Array<
                                                keyof IUser
                                            >,
                                        );
                                    }}
                                >
                                    <span className="text-sm font-semibold">
                                        {group.title}
                                    </span>
                                </Checkbox>
                            </div>
                        ),
                        children: group.children.map((child) => {
                            const column = UserColumns.find(
                                (col) => col.key === child,
                            );
                            return {
                                key: child,
                                label: (
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <Checkbox
                                            checked={checkedList.includes(
                                                child as keyof IUser,
                                            )}
                                            onChange={(e) => {
                                                const newCheckedList = e.target
                                                    .checked
                                                    ? [
                                                          ...checkedList,
                                                          child as keyof IUser,
                                                      ]
                                                    : checkedList.filter(
                                                          (item) =>
                                                              item !== child,
                                                      );
                                                setCheckedList(newCheckedList);
                                            }}
                                        >
                                            <span className="text-xs">
                                                {column?.title?.toString()}
                                            </span>
                                        </Checkbox>
                                    </div>
                                ),
                            };
                        }),
                    })),
                }}
                trigger={['click']}
                dropdownRender={(menu) => (
                    <div
                        style={{
                            maxHeight: '50vh',
                            overflowY: 'auto',
                        }}
                    >
                        {menu}
                    </div>
                )}
            >
                <Button
                    style={{ width: '100%', marginTop: 4 }}
                    icon={<UnorderedListOutlined />}
                >
                    Select Columns ({checkedList.length}) <DownOutlined />
                </Button>
            </Dropdown>
        </Col>
    );
};
