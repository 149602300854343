/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Modal, Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import {
    ActiveAccounts,
    EarnStats,
    GasSponsored,
    NewAccounts,
    SwapRevenue,
    TxCount,
} from 'components';
import type { ModalController } from 'hooks/useModalController';
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';
import type { $MixedElement } from 'types';
import { ChartInterval } from 'utils';

import type { StatsQuery } from '.graphclient';

type Props = {
    modalController: ModalController;
    charts: Array<CheckboxValueType>;
    data: StatsQuery;
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
};

export const ExportChartsModal = ({
    modalController,
    charts,
    data,
    isLoading,
    tokenPrices,
}: Props): $MixedElement => {
    const chartRef = useRef<HTMLDivElement>(null);

    const genPdf = (): void => {
        generatePDF(chartRef, {
            filename: 'chart.pdf',
            resolution: 5,
            page: {
                format: 'letter',
                orientation: 'landscape',
            },
        });
    };

    return (
        <>
            <Modal
                title={`Export Charts`}
                centered
                maskClosable={false}
                open={modalController.isOpen}
                onCancel={modalController.close}
                onOk={genPdf}
                okText={'Export'}
                width={window.innerWidth * 0.9}
            >
                <div className="grid grid-cols-3" ref={chartRef}>
                    {charts.map((chart) => {
                        switch (chart) {
                            case 'newAccounts-weekly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            New Accounts Weekly
                                        </Typography.Title>
                                        <NewAccounts
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Weekly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'newAccounts-monthly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            New Accounts Monthly
                                        </Typography.Title>
                                        <NewAccounts
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Monthly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'newAccounts-cumulative':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            New Accounts Cumulative
                                        </Typography.Title>
                                        <NewAccounts
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Cumulative
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'activeAccounts-weekly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Active Accounts Weekly
                                        </Typography.Title>
                                        <ActiveAccounts
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Weekly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'activeAccounts-monthly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Active Accounts Monthly
                                        </Typography.Title>
                                        <ActiveAccounts
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Monthly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'activeAccounts-cumulative':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Active Accounts Cumulative
                                        </Typography.Title>
                                        <ActiveAccounts
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Cumulative
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'gasSponsored-weekly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Gas Sponsored Weekly
                                        </Typography.Title>
                                        <GasSponsored
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Weekly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'gasSponsored-monthly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Gas Sponsored Monthly
                                        </Typography.Title>
                                        <GasSponsored
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Monthly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'gasSponsored-cumulative':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Gas Sponsored Cumulative
                                        </Typography.Title>
                                        <GasSponsored
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Cumulative
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'earnStats-weekly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Earn Stats Weekly
                                        </Typography.Title>
                                        <EarnStats
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Weekly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'earnStats-monthly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Earn Stats Monthly
                                        </Typography.Title>
                                        <EarnStats
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Monthly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'earnStats-cumulative':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Earn Stats Cumulative
                                        </Typography.Title>
                                        <EarnStats
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Cumulative
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'swapRevenue-weekly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Swap Revenue Weekly
                                        </Typography.Title>
                                        <SwapRevenue
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Weekly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'swapRevenue-monthly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Swap Revenue Monthly
                                        </Typography.Title>
                                        <SwapRevenue
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Monthly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'swapRevenue-cumulative':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Swap Revenue Cumulative
                                        </Typography.Title>
                                        <SwapRevenue
                                            data={data}
                                            isLoading={isLoading}
                                            tokenPrices={tokenPrices}
                                            defaultChartInterval={
                                                ChartInterval.Cumulative
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'txCount-weekly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Tx Count Weekly
                                        </Typography.Title>
                                        <TxCount
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Weekly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'txCount-monthly':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Tx Count Monthly
                                        </Typography.Title>
                                        <TxCount
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Monthly
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            case 'txCount-cumulative':
                                return (
                                    <div className="grid grid-cols-1">
                                        <Typography.Title level={5}>
                                            Tx Count Cumulative
                                        </Typography.Title>
                                        <NewAccounts
                                            data={data}
                                            isLoading={isLoading}
                                            defaultChartInterval={
                                                ChartInterval.Cumulative
                                            }
                                            isPreview={true}
                                        />
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })}
                </div>
            </Modal>
        </>
    );
};
