/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import type { ReactNode } from 'react';

export const useNotification = (): [
    (text: string, placement?: NotificationPlacement) => void,
    ReactNode,
] => {
    const [api, contextHolder] = notification.useNotification();

    const show = (
        text: string,
        placement: NotificationPlacement = 'bottom',
    ): void => {
        api.info({
            message: text,
            placement,
        });
    };

    return [show, contextHolder];
};
