/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Checkbox, Col, Modal, Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { ModalController } from 'hooks/useModalController';
import type { $MixedElement } from 'types';
import { Charts } from 'utils';

type Props = {
    modalController: ModalController;
    exportModalController: ModalController;
    setCharts: (charts: Array<CheckboxValueType>) => void;
};

export const ChartPickerModal = ({
    modalController,
    exportModalController,
    setCharts,
}: Props): $MixedElement => {
    const onChange: (checkedValue: Array<CheckboxValueType>) => void = (
        checkedValues,
    ) => {
        setCharts(checkedValues);
    };

    return (
        <>
            <Modal
                title={`Pick Charts to Export`}
                centered
                maskClosable={false}
                open={modalController.isOpen}
                onCancel={modalController.close}
                onOk={(modalController.close, exportModalController.open)}
                okText={'Preview'}
                width={650}
            >
                <Checkbox.Group
                    onChange={onChange}
                    className="grid grid-cols-2"
                >
                    <CheckBoxCol chart={Charts.NewAccounts} />
                    <CheckBoxCol chart={Charts.ActiveAccounts} />
                    <CheckBoxCol chart={Charts.GasSponsored} />
                    <CheckBoxCol chart={Charts.EarnStats} />
                    <CheckBoxCol chart={Charts.SwapRevenue} />
                    <CheckBoxCol chart={Charts.TxCount} />
                </Checkbox.Group>
            </Modal>
        </>
    );
};

export const CheckBoxCol = ({ chart }: { chart: Charts }): $MixedElement => {
    return (
        <Col style={{ margin: 10 }}>
            <Col>
                <Typography.Title level={5}>{chart}</Typography.Title>
            </Col>
            <Checkbox value={`${chart}-weekly`}>weekly</Checkbox>
            <Checkbox value={`${chart}-monthly`}>monthly</Checkbox>
            <Checkbox
                value={`${chart}-cumulative`}
                disabled={chart === Charts.ActiveAccounts}
            >
                cumulative
            </Checkbox>
        </Col>
    );
};
