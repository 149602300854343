/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetRaffles } from 'api';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult, RaffleResponse } from 'api/types';

const defaultResponse: RaffleResponse = {
    data: [],
    count: 0,
    next: false,
};

export const useGetRafflesQuery = (
    page: number,
): CustomQueryResult<RaffleResponse> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.RAFFLES, page],
        queryFn: async () => {
            const raffles = await apiGetRaffles(page);

            return raffles.data;
        },
    });

    return { data: data ?? defaultResponse, refetch: refetch, ...rest };
};
