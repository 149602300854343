/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiGetTranslations } from 'api';
import type { CustomMutationResult, TranslationResponseDto } from 'api/types';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type DataType = Array<TranslationResponseDto>;

export const useGetTranslationsMutation = (): [
    CustomMutationResult<DataType, void>,
    DataType,
    Dispatch<SetStateAction<DataType>>,
] => {
    const [translations, setTranslations] = useState<DataType>([]);

    const mutation = useMutation({
        mutationFn: async () => {
            const response = await apiGetTranslations();
            setTranslations(response.data);
            return response.data.reverse();
        },
    });

    useEffect(() => {
        mutation.mutate();
    }, []);

    return [mutation, translations, setTranslations];
};
