/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Editor } from '@monaco-editor/react';
import { Row, Typography } from 'antd';
import { Buffer } from 'buffer';
import { Layout, Sidebar } from 'components';
import { useState } from 'react';
import type { $MixedElement } from 'types';

export const DevtoolsBase64 = (): $MixedElement => {
    const [base64, setBase64] = useState('');
    const [base64Url, setBase64Url] = useState('');

    const editorOptions = {
        height: '75vh',
        width: '100%',
        options: {
            fontSize: 14,
        },
    };

    const base64toBase64Url = (base64: string): string => {
        return base64
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/\=+$/m, '');
    };

    const base64UrlToBase64 = (base64Url: string): string => {
        return padString(base64Url.replace(/\-/g, '+').replace(/\_/g, '/'));
    };

    const padString = (input: string): string => {
        const segmentLength = 4;
        const stringLength = input.length;
        const diff = stringLength % segmentLength;

        if (!diff) {
            return input;
        }

        let position = stringLength;
        let padLength = segmentLength - diff;
        const paddedStringLength = stringLength + padLength;
        const buffer = Buffer.alloc(paddedStringLength);

        buffer.write(input);

        while (padLength--) {
            buffer.write('=', position++);
        }

        return buffer.toString();
    };

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <Typography.Title level={1}>JSON - ENV Parser</Typography.Title>
                <Row>
                    <div className="w-[50%] flex-col">
                        <Typography.Title level={4} className="mb-4">
                            Base64
                        </Typography.Title>
                        <Editor
                            {...editorOptions}
                            value={base64}
                            language="txt"
                            onChange={(e): void => {
                                if (e != null) {
                                    setBase64(e);

                                    try {
                                        setBase64Url(base64toBase64Url(e));
                                    } catch {}
                                }
                            }}
                        />
                    </div>
                    <div className="w-[50%] flex-col">
                        <Typography.Title level={4} className="mb-4">
                            Base64Url
                        </Typography.Title>
                        <Editor
                            {...editorOptions}
                            value={base64Url}
                            onChange={(e): void => {
                                if (e != null) {
                                    setBase64Url(e);
                                    try {
                                        setBase64(base64UrlToBase64(e));
                                    } catch (err) {
                                        console.log(err);
                                    }
                                }
                            }}
                            language={'txt'}
                            options={{
                                ...editorOptions.options,
                            }}
                        />
                    </div>
                </Row>
            </Layout>
        </Row>
    );
};
