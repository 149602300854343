/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { PROVIDER } from 'api';
import { PAYMASTER_ADDRESSES } from 'const';
import type { BigNumber } from 'ethers';
import { utils } from 'ethers';
import { useEffect } from 'react';
import { useSetPaymasterBalance } from 'store';

type ReturnType = {
    getPaymasterBalancesMutation: UseMutationResult<void, Error, void, unknown>;
};

export const useGetPaymasterBalances = (
    notification?: (text: string, placement?: NotificationPlacement) => void,
): ReturnType => {
    const MIN_ETH_AMOUNT_FOR_NOTIFICATION = 0.025;
    const setPaymasterBalance = useSetPaymasterBalance();

    const getBalances = async (): Promise<void> => {
        const [
            gaslessBalance,
            erc20Balance,
            deployerBalance,
            recovererBalance,
        ] = await Promise.all([
            PROVIDER.getBalance(PAYMASTER_ADDRESSES.GASLESS),
            PROVIDER.getBalance(PAYMASTER_ADDRESSES.ERC20),
            PROVIDER.getBalance(PAYMASTER_ADDRESSES.DEPLOYER),
            PROVIDER.getBalance(PAYMASTER_ADDRESSES.RECOVERER),
        ]);

        const balances = {
            gasless: gaslessBalance,
            erc20: erc20Balance,
            deployer: deployerBalance,
            recoverer: recovererBalance,
        };

        setPaymasterBalance(balances);

        if (notification != null && isLowBalance(balances)) {
            notification(
                'Paymaster balances are low, check it from dashboard/paymaster',
                'top',
            );
        }
    };

    const getPaymasterBalancesMutation = useMutation({
        mutationFn: getBalances,
    });

    useEffect(() => {
        const balanceFn = async (): Promise<void> => {
            await getPaymasterBalancesMutation.mutateAsync();
        };
        balanceFn();
    }, []);

    const isLowBalance = (balances: Record<string, BigNumber>): boolean => {
        return Object.values(balances).some((balance) => {
            return (
                Number(utils.formatEther(balance)) <
                MIN_ETH_AMOUNT_FOR_NOTIFICATION
            );
        });
    };

    return { getPaymasterBalancesMutation };
};
