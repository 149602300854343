/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetSnapshots } from 'api';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult, SnapshotDto } from 'api/types';

export const useGetSnapshotsQuery = (): CustomQueryResult<SnapshotDto> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.SNAPSHOTS],
        queryFn: async () => {
            const snapshots = await apiGetSnapshots();
            return snapshots.data;
        },
        gcTime: Infinity,
        staleTime: Infinity,
    });

    return {
        data: data ?? { idle: [], invested: [] },
        refetch: refetch,
        ...rest,
    };
};
