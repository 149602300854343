/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { LinkOutlined } from '@ant-design/icons';
import { Avatar, List, Skeleton, Space, Tag, Typography } from 'antd';
import type { HistoryTransaction } from 'api/types';
import { formatDistance } from 'date-fns';
import { getTagColor } from 'utils/transactions';
import { formatTransactionMessage } from 'utils/transactions/formatters';

interface TransactionItemProps {
    transaction: HistoryTransaction;
    loading?: boolean;
    showUnknown?: boolean;
}

export const TransactionItem: React.FC<TransactionItemProps> = ({
    transaction,
    loading = false,
    showUnknown = false,
}) => {
    if (loading) {
        return (
            <List.Item>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Skeleton active paragraph={{ rows: 1 }} />
                </Space>
            </List.Item>
        );
    }

    const explorerUrl = `https://explorer.zksync.io/tx/${transaction.hash}`;

    // Handle transactions without clave_data
    if (!transaction.clave_data?.length) {
        if (!showUnknown) {
            return null;
        } else {
            return (
                <List.Item
                    style={{
                        padding: '16px',
                        background: '#fff',
                        borderRadius: '8px',
                        marginBottom: '8px',
                        maxWidth: '800px',
                        margin: '8px auto',
                    }}
                >
                    <Space
                        style={{
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Space size={12}>
                            <Avatar style={{ backgroundColor: '#d9d9d9' }}>
                                ?
                            </Avatar>
                            <Space direction="vertical" size={4}>
                                <Space size={8}>
                                    <Tag color="default">UNKNOWN</Tag>
                                    <Typography.Text
                                        type="secondary"
                                        style={{ fontSize: '12px' }}
                                    >
                                        {formatDistance(
                                            new Date(
                                                transaction.timestamp * 1000,
                                            ),
                                            new Date(),
                                            { addSuffix: true },
                                        )}
                                    </Typography.Text>
                                </Space>
                            </Space>
                        </Space>
                    </Space>
                    <Typography.Link
                        href={explorerUrl}
                        target="_blank"
                        style={{
                            fontSize: '12px',
                            color: '#8c8c8c',
                            marginLeft: '44px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        <LinkOutlined /> View on Explorer
                    </Typography.Link>
                </List.Item>
            );
        }
    }

    return (
        <List.Item
            style={{
                padding: '20px 24px',
                background: '#fff',
                borderRadius: '12px',
                marginBottom: '12px',
                width: '100%',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
                transition: 'transform 0.2s, box-shadow 0.2s',
            }}
        >
            <Space direction="vertical" style={{ width: '100%' }} size={8}>
                {transaction.clave_data.map((data, index) => {
                    const { message, leftAvatar, rightAvatar, type } =
                        formatTransactionMessage(data);
                    return (
                        <Space
                            key={index}
                            style={{
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                            align="center"
                        >
                            <Space size={16}>
                                <Avatar
                                    size={44}
                                    src={leftAvatar}
                                    style={{
                                        backgroundColor: !leftAvatar
                                            ? '#f56a00'
                                            : undefined,
                                        border: '2px solid #fff',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    }}
                                >
                                    {!leftAvatar &&
                                        type.charAt(0).toUpperCase()}
                                </Avatar>
                                <Space direction="vertical" size={6}>
                                    <Space size={8} align="center">
                                        <Tag
                                            color={getTagColor(type)}
                                            style={{
                                                padding: '2px 8px',
                                                borderRadius: '4px',
                                                fontSize: '12px',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {type.toLowerCase()}
                                        </Tag>
                                        <Typography.Text
                                            type="secondary"
                                            style={{
                                                fontSize: '12px',
                                                marginLeft: '4px',
                                            }}
                                        >
                                            {formatDistance(
                                                new Date(
                                                    transaction.timestamp *
                                                        1000,
                                                ),
                                                new Date(),
                                                { addSuffix: true },
                                            )}
                                        </Typography.Text>
                                    </Space>
                                    <Typography.Text
                                        style={{
                                            fontSize: '15px',
                                            lineHeight: '1.4',
                                        }}
                                    >
                                        {message}
                                    </Typography.Text>
                                </Space>
                            </Space>
                            <Space align="center" size={16}>
                                {rightAvatar && (
                                    <Avatar
                                        size={type === 'invest' ? 36 : 44}
                                        src={rightAvatar}
                                        style={{
                                            backgroundColor:
                                                !rightAvatar.startsWith(
                                                    '/protocols/',
                                                )
                                                    ? '#f56a00'
                                                    : undefined,
                                            border: '2px solid #fff',
                                            boxShadow:
                                                '0 2px 4px rgba(0,0,0,0.1)',
                                            padding:
                                                type === 'invest'
                                                    ? '4px'
                                                    : undefined,
                                            background:
                                                type === 'invest'
                                                    ? '#fff'
                                                    : undefined,
                                        }}
                                    >
                                        {!rightAvatar.startsWith(
                                            '/protocols/',
                                        ) &&
                                            rightAvatar.charAt(0).toUpperCase()}
                                    </Avatar>
                                )}
                                <Typography.Link
                                    href={explorerUrl}
                                    target="_blank"
                                    style={{
                                        fontSize: '13px',
                                        color: '#8c8c8c',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px',
                                    }}
                                >
                                    <LinkOutlined /> View
                                </Typography.Link>
                            </Space>
                        </Space>
                    );
                })}
            </Space>
        </List.Item>
    );
};
