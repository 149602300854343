/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetGatekeeperByName, apiGetGatekeepers } from 'api';
import { queryKeys } from 'api/queryKeys';
import type {
    CustomQueryResult,
    Gatekeeper,
    GatekeeperResponse,
} from 'api/types';

const defaultResponse: GatekeeperResponse = [] as const;

export const useGetGatekeepersQuery =
    (): CustomQueryResult<GatekeeperResponse> => {
        const { data, refetch, ...rest } = useQuery({
            queryKey: [queryKeys.GATEKEEPERS],
            queryFn: async () => {
                const { data } = await apiGetGatekeepers();
                return data;
            },
            staleTime: Infinity,
            gcTime: Infinity,
        });

        return { data: data ?? defaultResponse, refetch: refetch, ...rest };
    };

export const useGetGatekeeperQuery = (
    name: string,
): CustomQueryResult<Gatekeeper | null> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.GATEKEEPERS_BY_NAME, name],
        queryFn: async () => {
            const { data } = await apiGetGatekeeperByName(name);
            return data;
        },
        staleTime: Infinity,
        gcTime: Infinity,
    });

    return { data: data ?? null, refetch: refetch, ...rest };
};
