/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult } from 'api/types';
import { useEffect, useState } from 'react';

import { type BalancesQuery, getBuiltGraphSDK } from '.graphclient';

const sdk = getBuiltGraphSDK();

const emptyStats: BalancesQuery = {
    erc20Balances: [],
};

const fetchBalances = async (lastID: string): Promise<BalancesQuery> => {
    const data = await sdk.Balances({ lastID });
    return data;
};

export const useGetBalancesQuery = (): CustomQueryResult<BalancesQuery> => {
    const [allData, setAllData] = useState<BalancesQuery>(emptyStats);
    const [lastID, setLastId] = useState('');
    const [isFetching, setIsFetching] = useState(true);

    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.BALANCES, lastID],
        queryFn: async () => fetchBalances(lastID),
        staleTime: Infinity,
        gcTime: Infinity,
        enabled: isFetching,
    });

    useEffect(() => {
        if (data) {
            if (data.erc20Balances.length === 0) {
                setIsFetching(false);
            } else {
                setAllData((prevData) => ({
                    erc20Balances: [
                        ...prevData.erc20Balances,
                        ...data.erc20Balances,
                    ],
                }));
                setLastId(data.erc20Balances[data.erc20Balances.length - 1].id);
            }
        }
    }, [data]);

    // Trigger fetching again when lastID changes
    useEffect(() => {
        if (isFetching) {
            refetch();
        }
    }, [lastID, isFetching, refetch]);

    const restSafe = {
        ...rest,
        isFetching,
    };

    return { data: allData ?? emptyStats, refetch, ...restSafe };
};
