/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Input, Modal } from 'antd';
import { useNotification } from 'hooks';
import type { ModalController } from 'hooks/useModalController';
import { useState } from 'react';
import type { $MixedElement } from 'types';

import { useGenerateWLCodesMutation } from '../../api/mutations/useGenerateWLCodesMutation';

type Props = {
    modalController: ModalController;
};

export const GenerateWLCodeModal = ({
    modalController,
}: Props): $MixedElement => {
    const generateWLCodesMutation = useGenerateWLCodesMutation();
    const [show, contextHolder] = useNotification();
    const [count, setCount] = useState<string>('');

    const onCancel = (): void => {
        modalController.close();
    };

    const onGenerate = async (): Promise<void> => {
        const generated = await generateWLCodesMutation.mutateAsync({
            count: Number(count),
        });
        if (generated) {
            show('Waitlist codes successfully generated!');
            modalController.close();
        } else {
            show('Waitlist codes could not be generated!');
        }
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={`Generate Waitlist Code`}
                centered
                maskClosable={false}
                okText={'Generate'}
                open={modalController.isOpen}
                onOk={onGenerate}
                okButtonProps={{
                    loading: generateWLCodesMutation.isPending,
                }}
                onCancel={onCancel}
                width={512}
            >
                <Input
                    value={count}
                    onChange={(e): void => {
                        setCount(e.target.value);
                    }}
                    placeholder="Count of codes to generate"
                />
            </Modal>
        </>
    );
};
