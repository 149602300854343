/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const useClaveNavigation = (): NavigateFunction => {
    const navigate = useNavigate();
    return navigate;
};
