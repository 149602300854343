/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiPostGenerateWLCode } from 'api';

import type { CustomMutationResult, GenerateCodesRequestDto } from '../types';

export const useGenerateWLCodesMutation = (): CustomMutationResult<
    boolean,
    GenerateCodesRequestDto
> => {
    const mutation = useMutation({
        mutationFn: async (requestDto: GenerateCodesRequestDto) => {
            try {
                await apiPostGenerateWLCode(requestDto);
                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
    });

    return mutation;
};
