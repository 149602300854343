/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import Editor from '@monaco-editor/react';
import { Modal } from 'antd';
import { useUpdateTranslationMutation } from 'api/mutations/useUpdateTranslationMutation';
import type { CustomMutationResult, TranslationResponseDto } from 'api/types';
import { useNotification } from 'hooks';
import type { ModalController } from 'hooks/useModalController';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import type { $MixedElement } from 'types';
import { formatJson } from 'utils';

type Props = {
    selectedLocale: TranslationResponseDto;
    setSelectedLocale: Dispatch<SetStateAction<TranslationResponseDto | null>>;
    modalController: ModalController;
    mutation: CustomMutationResult<Array<TranslationResponseDto>, void>;
};

export const LocaleEditorModal = ({
    modalController,
    selectedLocale,
    setSelectedLocale,
    mutation: translationMutation,
}: Props): $MixedElement => {
    const [value, setValue] = useState<string>(formatJson(selectedLocale.data));
    const [show, contextHolder] = useNotification();

    useEffect(() => {
        if (!modalController.isOpen) {
            setValue('');
            setSelectedLocale(null);
        } else {
            setValue(formatJson(selectedLocale.data));
        }
    }, [modalController.isOpen, selectedLocale]);

    const mutation = useUpdateTranslationMutation('update', () => {
        modalController.close();
        show('Changes saved successfully');
        translationMutation.mutate();
    });

    const onCancel = (): void => {
        modalController.close();
    };

    const onSave = (): void => {
        let data = null;

        try {
            data = JSON.parse(value);
        } catch (err) {
            show('Invalid JSON structure');
            return;
        }

        const oldVersion = Number(selectedLocale.version);
        let newVersion = 1;
        if (!isNaN(oldVersion)) {
            newVersion = oldVersion + 1;
        }

        mutation.mutate({
            language: selectedLocale.language,
            data,
            version: String(newVersion),
        });
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={`Edit locale "${selectedLocale.language}"`}
                centered
                maskClosable={false}
                okText={'Save'}
                open={modalController.isOpen}
                onOk={onSave}
                okButtonProps={{
                    loading: mutation.isPending,
                }}
                onCancel={onCancel}
                width={1280}
            >
                <Editor
                    height="75vh"
                    defaultLanguage="json"
                    defaultValue={value}
                    onChange={(e): void => {
                        if (e != null) {
                            setValue(e);
                        }
                    }}
                />
            </Modal>
        </>
    );
};
