/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { bytesToDate, getDays } from './date';
import type { DataKeys, LineData } from './types';
import type { StatsQuery } from '.graphclient';

export function getDailyLineData(
    data: StatsQuery,
    key: DataKeys,
    dateRange: [number, number],
): LineData {
    const days = getDays(data.days, dateRange);

    return days.map((day) => {
        return {
            x: bytesToDate(day.id),
            y: day[key],
        };
    });
}

export function getWeeklyLineData(data: StatsQuery, key: DataKeys): LineData {
    return data.weeks.map((week) => {
        return {
            x: bytesToDate(week.id),
            y: week[key],
        };
    });
}

export function getMonthlyLineData(data: StatsQuery, key: DataKeys): LineData {
    return data.months.map((month) => {
        return {
            x: bytesToDate(month.id),
            y: month[key],
        };
    });
}

export function getCumulativeLineData(
    data: StatsQuery,
    key: DataKeys,
): LineData {
    let cumulativeCount = 0;
    return data.weeks.map((week) => {
        cumulativeCount += week[key];
        return {
            x: bytesToDate(week.id),
            y: cumulativeCount,
        };
    });
}
