/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import Aws from 'assets/dashboard/aws.png';
import Betterstack from 'assets/dashboard/betterstack.svg';
import Figma from 'assets/dashboard/figma.png';
import Github from 'assets/dashboard/github.png';
import Gmail from 'assets/dashboard/gmail.png';
import Hashicorp from 'assets/dashboard/hashicorp.svg';
import Jira from 'assets/dashboard/jira.png';
import Notion from 'assets/dashboard/notion.png';
import Substack from 'assets/dashboard/substack.png';

type DashboardLink = {
    name: string;
    image: string;
    link: string;
};

export const getDashboardLinks = (email: string): Array<DashboardLink> => {
    return [
        {
            name: 'Gmail',
            image: Gmail,
            link: `https://mail.google.com/mail/u/?authuser=${email}`,
        },
        {
            name: 'Pull Requests',
            image: Github,
            link: 'https://github.com/pulls?q=is%3Aopen+is%3Apr+archived%3Afalse+user%3Agetclave+',
        },
        {
            name: 'Jira',
            image: Jira,
            link: 'https://ethylene.atlassian.net/jira/software/projects/OP/boards/4',
        },
        {
            name: 'Figma',
            image: Figma,
            link: 'https://www.figma.com/file/vrJLiCDheRTpamfn7US8nP/Clave-Design-File?type=design&node-id=337-1436&mode=design&t=2m6BfcmRVYV7PUZ6-0',
        },
        {
            name: 'AWS Console',
            image: Aws,
            link: 'https://eu-central-1.console.aws.amazon.com/console/home?region=eu-central-1',
        },
        {
            name: 'Substack',
            image: Substack,
            link: 'https://blog.getclave.io',
        },
        {
            name: 'Notion',
            image: Notion,
            link: 'https://www.notion.so/getclave',
        },
        {
            name: 'Hashicorp',
            image: Hashicorp,
            link: 'https://portal.cloud.hashicorp.com/orgs/a25b53af-73cd-4975-9aaf-045e36ac43bd',
        },
        {
            name: 'BetterStack',
            image: Betterstack,
            link: 'https://uptime.betterstack.com/team/147281/monitors',
        },
    ];
};
