/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult } from 'api/types';
import { useEffect, useState } from 'react';

import { type AllUsersStatsQuery, getBuiltGraphSDK } from '.graphclient';

const sdk = getBuiltGraphSDK();
const emptyStats: AllUsersStatsQuery = {
    claveAccounts: [],
};

const fetchAllStats = async (lastID: string): Promise<AllUsersStatsQuery> => {
    const data = await sdk.AllUsersStats({ lastID });
    return data;
};

export const useAllUsersStatsQuery =
    (): CustomQueryResult<AllUsersStatsQuery> => {
        const [allData, setAllData] = useState<AllUsersStatsQuery>(emptyStats);
        const [lastID, setLastId] = useState('');
        const [isFetching, setIsFetching] = useState(true);

        const { data, refetch, ...rest } = useQuery({
            queryKey: [queryKeys.ALL_STATS, lastID],
            queryFn: async () => fetchAllStats(lastID),
            staleTime: Infinity,
            gcTime: Infinity,
            enabled: isFetching,
        });

        useEffect(() => {
            if (data) {
                if (data.claveAccounts.length === 0) {
                    setIsFetching(false);
                } else {
                    setAllData((prevData) => ({
                        claveAccounts: [
                            ...prevData.claveAccounts,
                            ...data.claveAccounts,
                        ],
                    }));
                    setLastId(
                        data.claveAccounts[data.claveAccounts.length - 1].id,
                    );
                }
            }
        }, [data]);

        // Trigger fetching again when lastID changes
        useEffect(() => {
            if (isFetching) {
                refetch();
            }
        }, [lastID, isFetching, refetch]);

        const restSafe = {
            ...rest,
            isFetching,
        };

        return { data: allData ?? emptyStats, refetch, ...restSafe };
    };
