/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Col, Input, Modal } from 'antd';
import { queryClient } from 'api';
import { useCreateGatekeeperMutation } from 'api/mutations/useCreateGatekeeperMutation';
import { queryKeys } from 'api/queryKeys';
import { useNotification } from 'hooks';
import type { ModalController } from 'hooks/useModalController';
import { useState } from 'react';
import type { $MixedElement } from 'types';

type Props = {
    modalController: ModalController;
};

export const CreateGatekeeperModal = ({
    modalController,
}: Props): $MixedElement => {
    const createGatekeeperMutation = useCreateGatekeeperMutation();
    const [show, contextHolder] = useNotification();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const onCancel = (): void => {
        modalController.close();
    };

    const onGenerate = async (): Promise<void> => {
        const createdGk = await createGatekeeperMutation.mutateAsync({
            name,
            description,
            comparison: 'OR',
        });
        if (createdGk != null) {
            show('GK Created Succesfully');
            modalController.close();
            queryClient.refetchQueries({
                queryKey: [queryKeys.GATEKEEPERS],
            });
            queryClient.refetchQueries({
                queryKey: [queryKeys.GATEKEEPERS_BY_NAME],
            });
        } else {
            show(
                'GK creation failed. Please make sure that you have a unique GK',
            );
        }
    };

    return (
        <>
            {contextHolder}
            <Modal
                title="Create Gatekeeper"
                centered
                maskClosable={false}
                okText="Create"
                open={modalController.isOpen}
                onOk={onGenerate}
                okButtonProps={{
                    loading: createGatekeeperMutation.isPending,
                    disabled: name.trim() === '' || description.trim() === '',
                }}
                onCancel={onCancel}
            >
                <Col className="space-y-4 mt-4">
                    <Input
                        addonBefore="Name"
                        value={name}
                        onChange={(e): void => {
                            setName(e.target.value);
                        }}
                        placeholder="Unique name of gatekeper"
                    />
                    <Input.TextArea
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                        value={description}
                        placeholder="Description of the gatekeeper. Ex: 'Allow only users with backup'"
                    />
                </Col>
            </Modal>
        </>
    );
};
