/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiCreateRaffle } from 'api';

import type {
    CreateRaffleDto,
    CustomMutationResult,
    RaffleDto,
} from '../types';

export const useCreateRaffleMutation = (): CustomMutationResult<
    RaffleDto,
    CreateRaffleDto
> => {
    const mutation = useMutation({
        mutationFn: async (dto: CreateRaffleDto) => {
            const response = await apiCreateRaffle(dto);
            return response.data;
        },
    });

    return mutation;
};
