/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetScheduledNotifications } from 'api';
import { queryKeys } from 'api/queryKeys';
import {
    type CustomQueryResult,
    type ScheduledNotificationResponse,
} from 'api/types';

type Data = Array<ScheduledNotificationResponse>;

export const useScheduleNotificationsQuery = (): CustomQueryResult<Data> => {
    const { data, ...rest } = useQuery({
        queryKey: [queryKeys.SCHEDULED_NOTIFICATIONS],
        queryFn: async () => {
            const response = await apiGetScheduledNotifications();
            return response.data;
        },
    });

    return {
        data: data ?? ([] as Data),
        ...rest,
    };
};
