/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult } from 'api/types';
import { useEffect, useState } from 'react';

import { type AllSwapsQuery, getBuiltGraphSDK } from '.graphclient';

const sdk = getBuiltGraphSDK();

const emptyStats: AllSwapsQuery = {
    inAppSwaps: [],
};

const fetchAllSwaps = async (lastID: string): Promise<AllSwapsQuery> => {
    const data = await sdk.AllSwaps({ lastID });
    return data;
};

export const useAllSwapsQuery = (): CustomQueryResult<AllSwapsQuery> => {
    const queryClient = useQueryClient();
    const [allData, setAllData] = useState<AllSwapsQuery>(emptyStats);
    const [lastID, setLastId] = useState('');
    const [isFetching, setIsFetching] = useState(true);

    const {
        data,
        isFetching: isQueryFetching,
        refetch,
        ...rest
    } = useQuery({
        queryKey: [queryKeys.ALL_SWAPS, lastID],
        queryFn: async () => fetchAllSwaps(lastID),
        staleTime: Infinity,
        gcTime: Infinity,
        enabled: isFetching,
    });

    useEffect(() => {
        if (data) {
            if (data.inAppSwaps.length === 0) {
                setIsFetching(false);
            } else {
                setAllData((prevData) => ({
                    inAppSwaps: [...prevData.inAppSwaps, ...data.inAppSwaps],
                }));
                setLastId(data.inAppSwaps[data.inAppSwaps.length - 1].id);
            }
        }
    }, [data]);

    useEffect(() => {
        if (!isFetching && isQueryFetching) {
            queryClient.invalidateQueries({
                queryKey: [queryKeys.ALL_SWAPS],
            });
        }
    }, [isFetching, isQueryFetching, queryClient]);

    // Trigger fetching again when lastID changes
    useEffect(() => {
        if (isFetching) {
            refetch();
        }
    }, [lastID, isFetching, refetch]);

    const restSafe = {
        ...rest,
        isFetching,
    };

    return { data: allData ?? emptyStats, refetch, ...restSafe };
};
