/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Row, Typography } from 'antd';
import { Layout, Sidebar } from 'components';
import {
    useAccountDetails,
    useGetPaymasterBalances,
    useNotification,
} from 'hooks';
import type { $MixedElement } from 'types';
import { getDashboardLinks } from 'utils/useDashboardLinks';

export const Home = (): $MixedElement => {
    const [show, contextHolder] = useNotification();
    // Get paymaster balances, if amount is low, send notification
    useGetPaymasterBalances(show);
    const account = useAccountDetails();

    const links = getDashboardLinks(account?.email || '');

    return (
        <Row wrap={false}>
            {contextHolder}
            <Sidebar />
            <Layout>
                <Typography.Title level={1}>Dashboard</Typography.Title>

                <Typography.Title level={3}>
                    Welcome, {account?.given_name}!
                </Typography.Title>

                <Row gutter={[16, 16]}>
                    {links.map((link) => (
                        <div className="mr-3 w-[224px]" key={link.name}>
                            <a
                                target="_blank"
                                href={link.link}
                                className="bg-neutral-50 hover:bg-neutral-100 flex flex-col items-center p-4 rounded-lg cursor-pointer"
                            >
                                <img
                                    className="w-[80px] h-[80px]"
                                    src={link.image}
                                />
                                <Typography.Text className="text-lg font-semibold mt-4">
                                    {link.name}
                                </Typography.Text>
                            </a>
                        </div>
                    ))}
                </Row>
            </Layout>
        </Row>
    );
};
