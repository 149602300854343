/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Avatar, Button, Col, Row, Space, Typography } from 'antd';
import { Layout, Sidebar } from 'components';
import { ACCESS_TOKEN, Paths } from 'const';
import { useAccountDetails, useClaveNavigation } from 'hooks';
import { useCallback } from 'react';
import { useSetAuth } from 'store/AuthStore';
import type { $MixedElement } from 'types';
import { permissions } from 'utils';

export const Account = (): $MixedElement => {
    const setAuth = useSetAuth();
    const navigate = useClaveNavigation();
    const account = useAccountDetails();

    const logout = useCallback(() => {
        localStorage.removeItem(ACCESS_TOKEN);
        setAuth({
            isAuth: false,
        });
        permissions.reset();
        navigate(Paths.AUTH);
    }, []);

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <Typography.Title level={1}>Account</Typography.Title>
                <Space className="mb-12 mt-4 w-max rounded-lg" wrap size={16}>
                    <Col className="ml-auto mr-auto">
                        <Avatar src={account?.picture} size={128} />
                        <Typography.Title level={3} className="mt-3">
                            {account?.name}
                        </Typography.Title>
                        <Typography.Text>{account?.email}</Typography.Text>
                    </Col>
                </Space>
                <Button
                    className="w-max"
                    type="primary"
                    onClick={logout}
                    danger
                >
                    Logout
                </Button>
            </Layout>
        </Row>
    );
};
