/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button, Pagination, Row, Spin, Table, Typography } from 'antd';
import { useGetWaitlistCodesMutation } from 'api/mutations/useGetWaitlistCodesMutation';
import { GenerateWLCodeModal, Layout, Sidebar } from 'components';
import { useModalController } from 'hooks/useModalController';
import { useState } from 'react';
import type { $MixedElement } from 'types';

export const GenerateWLCode = (): $MixedElement => {
    const modalController = useModalController();
    const [page, setPage] = useState(0);

    const [mutation, waitlistCodes] = useGetWaitlistCodesMutation({
        page,
    });

    const dataSource = waitlistCodes.data.map((item) => {
        return {
            key: item.code,
            code: item.code,
            is_used: item.is_used ? 'true' : 'false',
            count: item.count,
        };
    });

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Remaining Count',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Is Used',
            dataIndex: 'is_used',
            key: 'is_used',
        },
    ];

    return (
        <Row wrap={false}>
            <GenerateWLCodeModal modalController={modalController} />
            <Sidebar />
            <Layout>
                <Row className="items-center" justify="space-between">
                    <Typography.Title className="mb-0" level={1}>
                        Generate Waitlist Codes
                    </Typography.Title>
                    <Button
                        type="primary"
                        onClick={(): void => {
                            modalController.open();
                        }}
                    >
                        Generate more
                    </Button>
                </Row>
                {mutation.isPending && (
                    <Spin tip="Loading" size="small">
                        <div className="p-12 bg-gray-100 rounded-sm" />
                    </Spin>
                )}
                {!mutation.isPending && waitlistCodes.data.length > 0 && (
                    <>
                        <div className="max-h-[80vh] overflow-auto">
                            <Table
                                pagination={false}
                                dataSource={dataSource}
                                columns={columns}
                            />
                        </div>
                        <Pagination
                            onChange={(e): void => {
                                setPage(e - 1);
                            }}
                            pageSize={50}
                            showSizeChanger={false}
                            className="mt-5 mx-auto"
                            current={page + 1}
                            defaultCurrent={1}
                            total={waitlistCodes.count}
                        />
                    </>
                )}
            </Layout>
        </Row>
    );
};
