/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiPostTranslation, apiPutTranslation } from 'api';
import type {
    CustomAxiosError,
    CustomMutationResult,
    TranslationResponseDto,
    TranslationsRequestDto,
} from 'api/types';

type OnSuccessType = () => void;
type OnErrorType = (e: string) => void;

export const useUpdateTranslationMutation = (
    variant: 'add' | 'update',
    onSuccess?: OnSuccessType,
    onError?: OnErrorType,
): CustomMutationResult<TranslationResponseDto, TranslationsRequestDto> => {
    const mutation = useMutation({
        mutationFn: async (requestDto: TranslationsRequestDto) => {
            let response;
            if (variant === 'add') {
                response = await apiPostTranslation(requestDto);
            } else {
                response = await apiPutTranslation(requestDto);
            }
            onSuccess?.();
            return response.data;
        },
        onError: (e: CustomAxiosError) => {
            onError?.(e.response?.data.message ?? 'Something went wrong');
        },
    });

    return mutation;
};
