/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiPutGatekeeper } from 'api';
import { type CustomMutationResult, type Gatekeeper } from 'api/types';

export const useUpdateGatekeeperMutation = (): CustomMutationResult<
    Gatekeeper,
    Partial<Gatekeeper>
> => {
    return useMutation({
        mutationFn: async (gk: Partial<Gatekeeper>) => {
            const response = await apiPutGatekeeper(gk);
            return response.data;
        },
    });
};
