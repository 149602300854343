/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

// DO NOT REORDER
export const addressToToken: Record<
    string,
    {
        symbol: string;
        decimals: number;
        precision?: number;
    }
> = {
    '0x0000000000000000000000000000000000000000': {
        symbol: 'ETH',
        decimals: 18,
        precision: 4,
    },
    '0x1d17cbcf0d6d143135ae902365d2e5e2a16538d4': {
        symbol: 'USDC',
        decimals: 6,
    },
    '0x493257fd37edb34451f62edf8d2a0c418852ba4c': {
        symbol: 'USDT',
        decimals: 6,
    },
    '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4': {
        symbol: 'USDC.e',
        decimals: 6,
    },
    '0xf00dad97284d0c6f06dc4db3c32454d4292c6813': {
        symbol: 'WETH',
        decimals: 18,
        precision: 4,
    },
    '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91': {
        symbol: 'WETH',
        decimals: 18,
        precision: 4,
    },
    '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05': {
        symbol: 'KOI',
        decimals: 18,
    },
    '0x47260090ce5e83454d5f05a0abbb2c953835f777': {
        symbol: 'SPACE',
        decimals: 18,
    },
    '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2': {
        symbol: 'HOLD',
        decimals: 18,
    },
    '0x503234f203fc7eb888eec8513210612a43cf6115': {
        symbol: 'LUSD',
        decimals: 18,
    },
    '0x31c2c031fdc9d33e974f327ab0d9883eae06ca4a': {
        symbol: 'ZF',
        decimals: 18,
    },
    '0x99bbe51be7cce6c8b84883148fd3d12ace5787f2': {
        symbol: 'VC',
        decimals: 18,
    },
    '0x9e22d758629761fc5708c171d06c2fabb60b5159': {
        symbol: 'WOO',
        decimals: 18,
    },
    '0x60e7fe7ae4461b535bb9eb40c20424c7c61063d0': {
        symbol: 'ZWIF',
        decimals: 18,
    },
    '0xb0c2bdc425fd01c33d8514f8be016070212bdc6a': {
        symbol: 'LMAO',
        decimals: 18,
    },
    '0x144b83555d8a3119b0a69a7bc2f0a0388308fee3': {
        symbol: 'AAI',
        decimals: 18,
    },
    '0x8e86e46278518efc1c5ced245cba2c7e3ef11557': {
        symbol: 'USD+',
        decimals: 6,
    },
    '0x5fc44e95eaa48f9eb84be17bd3ac66b6a82af709': {
        symbol: 'GRAI',
        decimals: 18,
    },
    '0x90059c32eeeb1a2aa1351a58860d98855f3655ad': {
        symbol: 'ONEZ',
        decimals: 18,
    },
    '0x4b9eb6c0b6ea15176bbf62841c6b2a8a398cb656': {
        symbol: 'DAI',
        decimals: 18,
    },
    '0xbbeb516fb02a01611cbbe0453fe3c580d7281011': {
        symbol: 'WBTC',
        decimals: 8,
        precision: 4,
    },
    '0x32fd44bb869620c0ef993754c8a00be67c464806': {
        symbol: 'rETH',
        decimals: 18,
        precision: 4,
    },
    '0x75af292c1c9a37b3ea2e6041168b4e48875b9ed5': {
        symbol: 'cbETH',
        decimals: 18,
        precision: 4,
    },
    '0x787c09494ec8bcb24dcaf8659e7d5d69979ee508': {
        symbol: 'MAV',
        decimals: 18,
    },
    '0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42': {
        symbol: 'MUTE',
        decimals: 18,
    },
    '0x79db8c67d0c33203da4efb58f7d325e1e0d4d692': {
        symbol: 'MEOW',
        decimals: 18,
    },
    '0xc6dac3a53d5d6de9d1d05aa6e28b8e9e41722601': {
        symbol: 'LIBERTAS',
        decimals: 18,
    },
    '0xe7895ed01a1a6aacf1c2e955af14e7cf612e7f9d': {
        symbol: 'LETH',
        decimals: 18,
    },
    '0x244c238325fc1bdf6eded726ee1b47d55895d944': {
        symbol: 'ZORRO',
        decimals: 18,
    },
    '0xd03465338226ea0178337f4abb16fdd6df529f57': {
        symbol: 'ITP',
        decimals: 18,
    },
    '0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e': {
        symbol: 'ZK',
        decimals: 18,
    },
    '0x703b52f2b28febcb60e1372858af5b18849fe867': {
        symbol: 'wstETH',
        decimals: 18,
        precision: 4,
    },
    '0x240f765af2273b0cab6caff2880d6d8f8b285fa4': {
        symbol: 'SWORD',
        decimals: 18,
    },
    '0xe87269fa38180a13e9bb3c487537f5282ef3e5d7': {
        symbol: 'APPA',
        decimals: 18,
    },
    '0xd4169e045bcf9a86cc00101225d9ed61d2f51af2': {
        symbol: 'wrsETH',
        decimals: 18,
        precision: 4,
    },
    '0x3beff5cc75f04e3717676dae3eeac8f57a8ca493': {
        symbol: 'BSMTI',
        decimals: 18,
    },
    '0xD78ABD81a3D57712a3af080dc4185b698Fe9ac5A': {
        symbol: 'XVS',
        decimals: 18,
    },
};

export const tokenAddressToSymbol = Object.fromEntries(
    Object.entries(addressToToken).map(([address, { symbol }]) => [
        address.toLowerCase(),
        symbol,
    ]),
);

export const tokenAddressToDecimal = Object.fromEntries(
    Object.entries(addressToToken).map(([address, { decimals }]) => [
        address.toLowerCase(),
        decimals,
    ]),
);

export const tokenAddressToPrecision = Object.fromEntries(
    Object.entries(addressToToken).map(([address, { precision }]) => [
        address.toLowerCase(),
        precision,
    ]),
);

export const symbolToAddress = Object.fromEntries(
    Object.entries(addressToToken).map(([address, { symbol }]) => [
        symbol,
        address,
    ]),
);
