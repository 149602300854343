/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult } from 'api/types';

import { type StatsQuery, getBuiltGraphSDK } from '.graphclient';

const sdk = getBuiltGraphSDK();

const emptyStats: StatsQuery = {
    days: [],
    weeks: [],
    months: [],
    total: null,
};

export const useStatsQuery = (): CustomQueryResult<StatsQuery> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.STATS],
        queryFn: async () => {
            return sdk.Stats();
        },
        gcTime: Infinity,
        staleTime: Infinity,
    });

    return { data: data ?? emptyStats, refetch: refetch, ...rest };
};
