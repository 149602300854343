/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ACCESS_TOKEN } from 'const';

export const useAccessToken = (): string | null => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    return token ?? null;
};
