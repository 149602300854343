/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button, Input, List, Row, Select, Typography } from 'antd';
import { queryClient } from 'api';
import { useUpdateGatekeeperMutation } from 'api/mutations/useUpdateGatekeeperMutation';
import { useGetGatekeeperQuery } from 'api/query/useGetGatekeepersQuery';
import { queryKeys } from 'api/queryKeys';
import { type GatekeeperCondition } from 'api/types';
import { Layout, Sidebar } from 'components';
import { Paths } from 'const';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { $MixedElement } from 'types';

export const GatekeeperDetails = (): $MixedElement => {
    const params = useParams();
    const { data: gatekeeper } = useGetGatekeeperQuery(params.name as string);

    const navigate = useNavigate();
    const [conditions, setConditions] = useState<
        Array<Omit<GatekeeperCondition, 'id'>>
    >([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [comparison, setComparison] = useState('AND');
    const [threshold, setThreshold] = useState(100);

    const updateGatekeeperMutation = useUpdateGatekeeperMutation();

    useEffect(() => {
        if (gatekeeper == null) {
            return;
        } else {
            setConditions(gatekeeper.conditions);
            setName(gatekeeper.name);
            setDescription(gatekeeper.description);
            setComparison(gatekeeper.comparison);
            setThreshold(gatekeeper.threshold);
        }
    }, [gatekeeper]);

    if (gatekeeper == null) {
        return null;
    }

    const updateCondition = (
        index: number,
        key: string,
        value: string,
    ): void => {
        setConditions((prev) => {
            return prev.map((c, i) => {
                if (index === i) {
                    return {
                        ...c,
                        [key]: value,
                    };
                }
                return c;
            });
        });
    };

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <Typography.Title level={2}>General</Typography.Title>
                <div className="w-full flex flex-col mx-auto space-y-4 mb-10">
                    <Input
                        disabled={true}
                        addonBefore="Name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                    <div className="w-full flex flex-col space-y-2">
                        <Typography.Text strong>Description</Typography.Text>
                        <Input.TextArea
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </div>
                    <Input
                        min={0}
                        max={100}
                        type="number"
                        addonBefore="Threshold"
                        value={threshold}
                        onChange={(e) => {
                            setThreshold(Number(e.target.value));
                        }}
                    />
                    <div className="w-full flex flex-col space-y-2">
                        <Typography.Text strong>Comparison</Typography.Text>
                        <Select
                            value={comparison}
                            onChange={(e) => {
                                setComparison(e);
                            }}
                        >
                            <Select.Option value="AND">AND</Select.Option>
                            <Select.Option value="OR">OR</Select.Option>
                        </Select>
                    </div>
                </div>

                <Typography.Title level={2}>Conditions</Typography.Title>
                <List
                    className="w-full"
                    itemLayout="horizontal"
                    dataSource={conditions}
                    renderItem={(item, index) => (
                        <List.Item
                            key={index}
                            className="rounded-lg bg-gray-50"
                            actions={[
                                <Button
                                    danger
                                    onClick={() => {
                                        setConditions((prev) => {
                                            return prev.filter(
                                                (_, i) => i !== index,
                                            );
                                        });
                                    }}
                                >
                                    Delete
                                </Button>,
                            ]}
                        >
                            <div className="grid grid-cols-5 w-full p-4 gap-4">
                                <Select
                                    placeholder="Field Type"
                                    onChange={(e) => {
                                        updateCondition(index, 'field_type', e);
                                    }}
                                    value={item.field_type}
                                >
                                    {FieldTypeOptions}
                                </Select>
                                <Input
                                    placeholder="Field"
                                    onChange={(e) => {
                                        updateCondition(
                                            index,
                                            'field',
                                            e.target.value,
                                        );
                                    }}
                                    value={item.field}
                                />
                                <Select
                                    placeholder="Comparator"
                                    onChange={(e) => {
                                        updateCondition(index, 'comparator', e);
                                    }}
                                    value={item.comparator}
                                >
                                    {ComparatorOptions}
                                </Select>
                                <Input
                                    placeholder="Compared Value"
                                    onChange={(e) => {
                                        updateCondition(
                                            index,
                                            'compared_value',
                                            e.target.value,
                                        );
                                    }}
                                    value={item.compared_value}
                                />
                                <Input
                                    placeholder="Threshold"
                                    type="number"
                                    min={0}
                                    max={100}
                                    onChange={(e) => {
                                        updateCondition(
                                            index,
                                            'threshold',
                                            e.target.value,
                                        );
                                    }}
                                    value={item.threshold}
                                />
                            </div>
                        </List.Item>
                    )}
                />
                <div className="w-max mt-4 mb-10 mx-auto space-x-4">
                    <Button
                        onClick={() => {
                            navigate(Paths.GK);
                        }}
                    >
                        Go Back
                    </Button>
                    <Button
                        onClick={() => {
                            setConditions([
                                ...conditions,
                                {
                                    field: '',
                                    field_type: 'STRING',
                                    compared_value: '',
                                    comparator: 'EQ',
                                    threshold: 0,
                                },
                            ]);
                        }}
                    >
                        Add new
                    </Button>
                    <Button
                        loading={updateGatekeeperMutation.isPending}
                        type="primary"
                        onClick={async () => {
                            const finalGatekeeper = {
                                ...gatekeeper,
                                name,
                                description,
                                comparison,
                                conditions,
                                threshold,
                            };
                            await updateGatekeeperMutation.mutateAsync(
                                finalGatekeeper,
                            );

                            queryClient.refetchQueries({
                                queryKey: [queryKeys.GATEKEEPERS],
                            });

                            queryClient.refetchQueries({
                                queryKey: [queryKeys.GATEKEEPERS_BY_NAME],
                            });

                            navigate(Paths.GK);
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Layout>
        </Row>
    );
};

const FieldTypeOptions = [
    <Select.Option value="NUMBER">Number</Select.Option>,
    <Select.Option value="STRING">String</Select.Option>,
    <Select.Option value="BOOLEAN">Boolean</Select.Option>,
    <Select.Option value="DATE">Date</Select.Option>,
];

const ComparatorOptions = [
    <Select.Option value="EQ">Equals</Select.Option>,
    <Select.Option value="NEQ">Not Equals</Select.Option>,
    <Select.Option value="GT">Greater Than</Select.Option>,
    <Select.Option value="LT">Less Than</Select.Option>,
    <Select.Option value="GTE">Great Than & Equal</Select.Option>,
    <Select.Option value="LTE">Less Than & Equal</Select.Option>,
    <Select.Option value="INCLUDES">Includes</Select.Option>,
    <Select.Option value="EXCLUDES">Excludes</Select.Option>,
];
