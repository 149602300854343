/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useIsSidebarOpenNullSafe } from 'store/SidebarStore';
import type { $MixedElement } from 'types';

type Props = {
    children: $MixedElement | Array<$MixedElement>;
};

export const Layout = ({ children }: Props): $MixedElement => {
    const isSidebarOpen = useIsSidebarOpenNullSafe();
    return (
        <div
            className={`flex-col flex py-6 h-screen overflow-auto flex-nowrap ${
                isSidebarOpen ? 'cds-layout px-8' : 'cds-layout-big px-4'
            }`}
        >
            <>{children}</>
        </div>
    );
};
