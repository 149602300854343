/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetOnrampStatsByDate } from 'api';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult, OnrampByDateDto } from 'api/types';

export const useGetOnrampStatsQuery = (): CustomQueryResult<
    Array<OnrampByDateDto>
> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.ONRAMP_STATS_BY_DATE],
        queryFn: async () => {
            const stats = await apiGetOnrampStatsByDate();
            return stats.data;
        },
        gcTime: Infinity,
        staleTime: Infinity,
    });

    return { data: data ?? [], refetch: refetch, ...rest };
};
