/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiGetUsers } from 'api';
import type { CustomMutationResult, UsersResponseDto } from 'api/types';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type DataType = UsersResponseDto;

type Query = {
    page?: number;
    debouncedSearch?: string;
};

export const useGetUsersMutation = (
    query: Query,
): [
    CustomMutationResult<DataType, Query>,
    DataType,
    Dispatch<SetStateAction<DataType>>,
] => {
    const [users, setUsers] = useState<UsersResponseDto>({
        data: [],
        count: 0,
        next: false,
    });

    const mutation = useMutation({
        mutationFn: async (query: Query) => {
            const response = await apiGetUsers({
                page: query.page,
                search: query.debouncedSearch,
                searchKey: query.debouncedSearch?.startsWith('0x')
                    ? 'address'
                    : 'username',
            });
            setUsers(response.data);
            return response.data;
        },
    });

    useEffect(() => {
        mutation.mutate(query);
    }, [query.page, query.debouncedSearch]);

    return [mutation, users, setUsers];
};
