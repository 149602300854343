/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetUserStatistics } from 'api';
import { queryKeys } from 'api/queryKeys';
import type {
    CustomQueryResult,
    UserStatisticsDto,
    UsersByDateDto,
} from 'api/types';

const emptyStats = {
    count: 0,
    deployed_count: 0,
    users_by_date: [] as Array<UsersByDateDto>,
};

export const useGetUserStatsQuery =
    (): CustomQueryResult<UserStatisticsDto> => {
        const { data, refetch, ...rest } = useQuery({
            queryKey: [queryKeys.USER_STATS],
            queryFn: async () => {
                const stats = await apiGetUserStatistics();
                return stats.data;
            },
            gcTime: Infinity,
            staleTime: Infinity,
        });

        return { data: data ?? emptyStats, refetch: refetch, ...rest };
    };
