/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button, Input, Pagination, Row, Spin, Table, Typography } from 'antd';
import { useGetGatekeepersQuery } from 'api/query/useGetGatekeepersQuery';
import {
    type GatekeeperCondition,
    type Gatekeeper as GatekeeperType,
} from 'api/types';
import { CreateGatekeeperModal, Layout, Sidebar } from 'components';
import { Paths } from 'const';
import { useDebounce } from 'hooks/useDebounce';
import { useModalController } from 'hooks/useModalController';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { $MixedElement } from 'types';

export const Gatekeeper = (): $MixedElement => {
    const [page, setPage] = useState(0);
    const { data: response, isPending } = useGetGatekeepersQuery();
    const navigate = useNavigate();
    const modalController = useModalController();
    const [search, setSearch] = useState('');

    const debouncedSearch = useDebounce(search, 500);

    const dataSource = useMemo(() => {
        return response
            .filter((item) => {
                return item.name
                    .toLowerCase()
                    .includes(debouncedSearch.toLowerCase());
            })
            .map((gk) => {
                return {
                    ...gk,
                    key: gk.id,
                };
            });
    }, [response, debouncedSearch]);

    const Columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Comparison',
            dataIndex: 'comparison',
            key: 'comparison',
        },
        {
            title: 'Threshold',
            dataIndex: 'threshold',
            key: 'threshold',
        },
        {
            title: 'Conditions',
            dataIndex: 'conditions',
            key: 'conditions',
            render: (
                conditions: Array<GatekeeperCondition>,
                record: GatekeeperType,
            ): $MixedElement => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(Paths.GK + '/' + record.name);
                        }}
                    >
                        {conditions.length} condition
                        {conditions.length > 1 ? 's' : ''}
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <CreateGatekeeperModal modalController={modalController} />
            <Row wrap={false}>
                <Sidebar />
                <Layout>
                    <Row className="items-center" justify="space-between">
                        <Typography.Title className="mb-0" level={1}>
                            Gatekeepers
                        </Typography.Title>
                        <div className="flex items-center space-x-4">
                            <Input
                                className="w-[200px]"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <Button
                                type="primary"
                                onClick={modalController.open}
                            >
                                New Gatekeeper
                            </Button>
                        </div>
                    </Row>

                    {isPending ? (
                        <Spin tip="Loading" size="small">
                            <div className="p-12 bg-gray-100 rounded-sm" />
                        </Spin>
                    ) : (
                        <>
                            <div className="max-h-[80vh] overflow-auto">
                                <Table
                                    pagination={false}
                                    dataSource={dataSource}
                                    columns={Columns}
                                />
                            </div>
                            <Pagination
                                onChange={(e): void => {
                                    setPage(e - 1);
                                }}
                                pageSize={50}
                                showSizeChanger={false}
                                className="mt-5 mx-auto"
                                current={page + 1}
                                defaultCurrent={1}
                                total={response.length}
                            />
                        </>
                    )}
                </Layout>
            </Row>
        </>
    );
};
