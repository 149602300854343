/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    DeleteOutlined,
    DownOutlined,
    FilterOutlined,
    PlusOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Dropdown,
    Input,
    Select,
    Switch,
    Tooltip,
    Typography,
} from 'antd';
import { type ModalController } from 'hooks/useModalController';
import type { $MixedElement, AllStatsFilter } from 'types';
import { type IUser, UserColumns } from 'utils';

type Props = {
    filters: Array<AllStatsFilter>;
    setFilters: (filters: Array<AllStatsFilter>) => void;
    setFilterCondition: (condition: 'AND' | 'OR') => void;
    saveFiltersController: ModalController;
};

export const AllStatsFilters = ({
    filters,
    setFilters,
    setFilterCondition,
    saveFiltersController,
}: Props): $MixedElement => {
    return (
        <Col span={6}>
            <Typography.Text strong>Filters:</Typography.Text>
            <Dropdown
                menu={{
                    className: 'max-h-[400px] overflow-auto min-w-[768px]',
                    items: [
                        {
                            key: 'addFilter',
                            label: (
                                <div className="p-4 border-b flex justify-between space-x-4 items-center border-gray-200">
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setFilters([
                                                ...filters,
                                                {
                                                    column: 'username',
                                                    value: '',
                                                    operator: 'eq',
                                                },
                                            ]);
                                        }}
                                    >
                                        Add Filter
                                    </Button>
                                    <div className="flex items-center space-x-4">
                                        <Typography.Text>
                                            Condition:
                                        </Typography.Text>
                                        <Switch
                                            checkedChildren="AND"
                                            unCheckedChildren="OR"
                                            defaultChecked
                                            onChange={(checked) => {
                                                setFilterCondition(
                                                    checked ? 'AND' : 'OR',
                                                );
                                            }}
                                            onClick={(_checked, e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        />
                                    </div>
                                    {filters.length > 0 && (
                                        <Button
                                            onClick={saveFiltersController.open}
                                            icon={<SaveOutlined />}
                                            type="default"
                                        >
                                            Save Filters
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                        ...filters.map((filter, index) => ({
                            key: `filter-${index}`,
                            label: (
                                <div
                                    className="p-4 flex w-[100%] space-x-4 hover:bg-gray-50"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Select
                                        className="w-[100%]"
                                        value={filter.column}
                                        onChange={(value) => {
                                            const newFilters = [...filters];
                                            newFilters[index].column =
                                                value as keyof IUser;
                                            setFilters(newFilters);
                                        }}
                                        options={UserColumns.map(
                                            ({ key, title }) => ({
                                                value: key,
                                                label: title,
                                            }),
                                        )}
                                    />
                                    <Select
                                        className="w-[100%]"
                                        value={filter.operator}
                                        onChange={(value) => {
                                            const newFilters = [...filters];
                                            newFilters[index].operator =
                                                value as
                                                    | 'eq'
                                                    | 'gt'
                                                    | 'lt'
                                                    | 'neq';
                                            setFilters(newFilters);
                                        }}
                                        options={[
                                            {
                                                value: 'eq',
                                                label: '=',
                                            },
                                            {
                                                value: 'gt',
                                                label: '>',
                                            },
                                            {
                                                value: 'lt',
                                                label: '<',
                                            },
                                            {
                                                value: 'neq',
                                                label: '!=',
                                            },
                                            {
                                                value: 'contains',
                                                label: 'contains',
                                            },
                                            {
                                                value: 'notContains',
                                                label: 'notContains',
                                            },
                                        ]}
                                    />
                                    <Input
                                        className="w-[100%]"
                                        value={filter.value}
                                        onChange={(e) => {
                                            const newFilters = [...filters];
                                            newFilters[index].value =
                                                e.target.value;
                                            setFilters(newFilters);
                                        }}
                                        placeholder="Value"
                                    />
                                    <Tooltip title="Remove filter">
                                        <Button
                                            type="text"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const newFilters =
                                                    filters.filter(
                                                        (_, i) => i !== index,
                                                    );
                                                setFilters(newFilters);
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            ),
                        })),
                    ].filter((item) => item != null),
                }}
                trigger={['click']}
            >
                <Button
                    style={{ width: '100%', marginTop: 4 }}
                    icon={<FilterOutlined />}
                >
                    Manage Filters ({filters.length}) <DownOutlined />
                </Button>
            </Dropdown>
        </Col>
    );
};
