/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ResponsiveLine } from '@nivo/line';
import { type $MixedElement } from 'types';
import type { LineChartData, LineChartProps } from 'utils';

export const LineChart = ({
    data,
    props,
}: {
    data: LineChartData;
    props: LineChartProps;
}): $MixedElement => {
    return (
        <ResponsiveLine
            data={data}
            colors={['rgba(0, 206, 209, 1']}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false,
            }}
            theme={{
                background: '#ffffff',
                grid: {
                    line: {
                        strokeWidth: 1,
                    },
                },
                axis: {
                    ticks: {
                        text: {
                            fontSize: 8,
                        },
                    },
                },
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            useMesh={true}
            axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: -25,
                legend: props.axisBottomLegend,
                legendOffset: 40,
                legendPosition: 'middle',
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: props.axisLeftLegend,
                legendOffset: -60,
                legendPosition: 'middle',
                tickValues: props.axisLeftTickValues,
                format: props.axisLeftFormat,
            }}
            margin={props.margin}
            yFormat={props.yFormat}
        />
    );
};
