/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Segmented, Spin, type TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    type BarChartData,
    type BarChartProps,
    ChartInterval,
    FEE_PCT,
    RangePicker,
    addressToToken,
    bytesToDate,
    dateFormat,
    formatUnits,
    getDays,
} from 'utils';

import { BarChart } from '../charts/BarChart';
import type { StatsQuery } from '.graphclient';

export const SwapRevenue = ({
    data,
    isLoading,
    tokenPrices,
    defaultChartInterval = ChartInterval.Weekly,
    isPreview = false,
}: {
    data: StatsQuery;
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
}): $MixedElement => {
    const [dateRange, setDateRange] = useState<[number, number]>([0, 0]);
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);
    type Key = 'eth' | 'usdc' | 'usdt' | 'meow' | 'zk' | 'other';

    const onDateChange: TimeRangePickerProps['onChange'] = (date) => {
        if (date && date[0] && date[1]) {
            setDateRange([date[0].unix(), date[1].unix()]);
            setChartInterval(ChartInterval.Daily);
        }
    };

    const chartData = useMemo(() => {
        const daily: Array<BarChartData<Key>> = [];
        const weekly: Array<BarChartData<Key>> = [];
        const monthly: Array<BarChartData<Key>> = [];
        const cumulative: Array<BarChartData<Key>> = [];

        if (tokenPrices == null) {
            return {
                [ChartInterval.Daily]: daily,
                [ChartInterval.Weekly]: weekly,
                [ChartInterval.Monthly]: monthly,
                [ChartInterval.Cumulative]: cumulative,
            };
        }

        const days = getDays(data.days, dateRange);

        days.forEach((day) => {
            const dailyData: BarChartData<Key> = {
                date: bytesToDate(day.id),
                eth: 0,
                usdc: 0,
                usdt: 0,
                meow: 0,
                zk: 0,
                other: 0,
            };

            day.swappedTo.forEach((swappedTo) => {
                const tokenAddress = swappedTo.erc20.toLowerCase();

                const token = addressToToken[tokenAddress];
                if (!token) {
                    return;
                }
                const decimals = token.decimals;

                const tokenPrice = tokenPrices[tokenAddress];
                if (!tokenPrice) {
                    return;
                }

                const amountOut = formatUnits(swappedTo.amount, decimals);
                const fee = amountOut * FEE_PCT;

                const usdValue = fee * tokenPrice;

                if (token.symbol === 'ETH') {
                    dailyData.eth += usdValue;
                } else if (
                    token.symbol === 'USDC' ||
                    token.symbol === 'USDC.e'
                ) {
                    dailyData.usdc += usdValue;
                } else if (token.symbol === 'USDT') {
                    dailyData.usdt += usdValue;
                } else if (token.symbol === 'MEOW') {
                    dailyData.meow += usdValue;
                } else if (token.symbol === 'ZK') {
                    dailyData.zk += usdValue;
                } else {
                    dailyData.other += usdValue;
                }
            });

            daily.push(dailyData);
        });

        data.weeks.forEach((week) => {
            const weeklyData: BarChartData<Key> = {
                date: bytesToDate(week.id),
                eth: 0,
                usdc: 0,
                usdt: 0,
                meow: 0,
                zk: 0,
                other: 0,
            };

            const cumulativeData: BarChartData<Key> =
                cumulative.length > 0
                    ? {
                          ...cumulative[cumulative.length - 1],
                          date: bytesToDate(week.id),
                      }
                    : { ...weeklyData };

            week.swappedTo.forEach((swappedTo) => {
                const tokenAddress = swappedTo.erc20.toLowerCase();

                const token = addressToToken[tokenAddress];
                if (!token) {
                    console.log('Token not found', tokenAddress);
                    return;
                }
                const decimals = token.decimals;

                const tokenPrice = tokenPrices[tokenAddress];
                if (!tokenPrice) {
                    console.log('Token price not found', tokenAddress);
                    return;
                }

                const amountOut = formatUnits(swappedTo.amount, decimals);
                const fee = amountOut * FEE_PCT;

                const usdValue = fee * tokenPrice;

                if (token.symbol === 'ETH') {
                    weeklyData.eth += usdValue;
                    cumulativeData.eth += usdValue;
                } else if (
                    token.symbol === 'USDC' ||
                    token.symbol === 'USDC.e'
                ) {
                    weeklyData.usdc += usdValue;
                    cumulativeData.usdc += usdValue;
                } else if (token.symbol === 'USDT') {
                    weeklyData.usdt += usdValue;
                    cumulativeData.usdt += usdValue;
                } else if (token.symbol === 'MEOW') {
                    weeklyData.meow += usdValue;
                    cumulativeData.meow += usdValue;
                } else if (token.symbol === 'ZK') {
                    weeklyData.zk += usdValue;
                    cumulativeData.zk += usdValue;
                } else {
                    weeklyData.other += usdValue;
                    cumulativeData.other += usdValue;
                }
            });

            weekly.push(weeklyData);
            cumulative.push(cumulativeData);
        });

        data.months.forEach((month) => {
            const monthlyData: BarChartData<Key> = {
                date: bytesToDate(month.id),
                eth: 0,
                usdc: 0,
                usdt: 0,
                meow: 0,
                zk: 0,
                other: 0,
            };

            month.swappedTo.forEach((swappedTo) => {
                const tokenAddress = swappedTo.erc20.toLowerCase();

                const token = addressToToken[tokenAddress];
                if (!token) {
                    return;
                }
                const decimals = token.decimals;

                const tokenPrice = tokenPrices[tokenAddress];
                if (!tokenPrice) {
                    return;
                }

                const amountOut = formatUnits(swappedTo.amount, decimals);
                const fee = amountOut * FEE_PCT;

                const usdValue = fee * tokenPrice;

                if (token.symbol === 'ETH') {
                    monthlyData.eth += usdValue;
                } else if (
                    token.symbol === 'USDC' ||
                    token.symbol === 'USDC.e'
                ) {
                    monthlyData.usdc += usdValue;
                } else if (token.symbol === 'USDT') {
                    monthlyData.usdt += usdValue;
                } else if (token.symbol === 'MEOW') {
                    monthlyData.meow += usdValue;
                } else if (token.symbol === 'ZK') {
                    monthlyData.zk += usdValue;
                } else {
                    monthlyData.other += usdValue;
                }
            });

            monthly.push(monthlyData);
        });

        return {
            [ChartInterval.Daily]: daily,
            [ChartInterval.Weekly]: weekly,
            [ChartInterval.Monthly]: monthly,
            [ChartInterval.Cumulative]: cumulative,
        };
    }, [data, tokenPrices, dateRange]);

    const barProps: BarChartProps<Key> = {
        keys: ['eth', 'usdc', 'usdt', 'meow', 'zk', 'other'],
        indexBy: 'date',
        axisBottomLegend: 'Date',
        axisLeftLegend: 'USD',
        enableTotals: !isPreview,
        valueFormat: ' >-$.2f',
    };

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }
    return (
        <div className="w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <>
                    <Segmented
                        options={[
                            ChartInterval.Daily,
                            ChartInterval.Weekly,
                            ChartInterval.Monthly,
                            ChartInterval.Cumulative,
                        ]}
                        value={chartInterval}
                        defaultValue={defaultChartInterval}
                        onChange={(value): void => {
                            if (
                                value === ChartInterval.Daily &&
                                dateRange[0] === 0
                            ) {
                                setDateRange([
                                    dayjs().subtract(1, 'week').unix() - 80000,
                                    dayjs().unix(),
                                ]);
                            }
                            setChartInterval(value as ChartInterval);
                        }}
                        style={{ marginBottom: 12, marginLeft: 6 }}
                    />
                    <RangePicker
                        onChange={onDateChange}
                        value={
                            dateRange[0] === 0
                                ? undefined
                                : [
                                      dayjs.unix(dateRange[0]),
                                      dayjs.unix(dateRange[1]),
                                  ]
                        }
                        minDate={dayjs('2024-01-21', dateFormat)}
                        maxDate={dayjs()}
                        style={{ marginLeft: 24 }}
                    />
                </>
            )}
            <BarChart data={chartData[chartInterval]} props={barProps} />
        </div>
    );
};
