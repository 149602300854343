/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { CDSSFIconObject } from '@getclave/sf-icons';
import { Input, Row, Typography } from 'antd';
import { Layout, Sidebar } from 'components';
import { useDebounce } from 'hooks/useDebounce';
import React, { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';

type Icon = {
    name: string;
    icon: string;
};

export const DevtoolsIcons = (): $MixedElement => {
    const [name, setName] = useState('');
    const debouncedName = useDebounce(name, 500);

    const icons = useMemo(() => {
        const keys = Object.keys(CDSSFIconObject);
        const icons: Array<Icon> = [];

        keys.forEach((key) => {
            if (key.toUpperCase().includes(debouncedName.toUpperCase())) {
                const icon =
                    CDSSFIconObject[key as keyof typeof CDSSFIconObject];

                icons.push({
                    name: key,
                    icon: typeof icon === 'string' ? icon : icon('white'),
                });
            }
        });

        return icons;
    }, [debouncedName]);

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <Typography.Title level={1}>Icons</Typography.Title>
                <Input
                    name={name}
                    onChange={(e): void => setName(e.target.value)}
                    size="large"
                    className="mb-8 w-[320px]"
                    placeholder="Search icon..."
                />
                <IconList icons={icons} />
            </Layout>
        </Row>
    );
};

const IconsComponent = ({ icons }: { icons: Array<Icon> }): $MixedElement => {
    return (
        <div className="grid grid-cols-6">
            {icons.map((item, index) => (
                <div className="flex flex-col mr-4 mb-8 items-center">
                    <div
                        className="mb-2 text-blue-900"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item.icon }}
                    />
                    <Typography.Text className="text-center">
                        {item.name}
                    </Typography.Text>
                </div>
            ))}
        </div>
    );
};

const IconList = React.memo(
    IconsComponent,
    ({ icons: prevIcons }, { icons: nextIcons }) =>
        prevIcons.length === nextIcons.length,
);
