/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { CredentialResponse } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { Col, Row, Typography } from 'antd';
import Background from 'assets/auth/background.png';
import { ACCESS_TOKEN, Paths } from 'const';
import { useClaveNavigation, useNotification } from 'hooks';
import jwt_decode from 'jwt-decode';
import { useSetAuth } from 'store/AuthStore';
import type { $MixedElement, Account } from 'types';
import { permissions } from 'utils';

export const Auth = (): $MixedElement => {
    const navigate = useClaveNavigation();
    const setAuth = useSetAuth();

    const [show, contextHolder] = useNotification();

    const onLogin = (credentialResponse: CredentialResponse): void => {
        localStorage.setItem(
            ACCESS_TOKEN,
            credentialResponse.credential as string,
        );
        show('Successfully logged in, redirecting');

        setTimeout(() => {
            navigate(Paths.HOME);
            const decoded = jwt_decode<Account>(
                credentialResponse.credential as string,
            );
            permissions.set(decoded.email);
            setAuth((auth) => ({
                ...auth,
                isAuth: true,
            }));
        }, 1250);
    };

    useGoogleOneTapLogin({
        onSuccess: (credentialResponse) => {
            onLogin(credentialResponse);
        },
        onError: () => {
            console.log('Login Failed');
        },
    });

    return (
        <>
            {contextHolder}
            <Row gutter={[12, 12]} className="min-h-screen" wrap={false}>
                <Col span={12} className="hidden lg:flex">
                    <img
                        className="object-cover w-full h-screen"
                        src={Background}
                    />
                </Col>
                <Col
                    span={12}
                    className="flex flex-col space-y-2 items-center justify-center m-auto"
                >
                    <Row>
                        <Typography.Title level={1}>
                            Clave Internal
                        </Typography.Title>
                    </Row>

                    <Row>
                        <GoogleLogin
                            onSuccess={(credentialResponse): void => {
                                onLogin(credentialResponse);
                            }}
                            onError={(): void => {
                                show('Failed to login, please try again later');
                            }}
                        />
                    </Row>
                </Col>
            </Row>
        </>
    );
};
