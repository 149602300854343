/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export type PaymasterAddress = {
    DEPLOYER: string;
    GASLESS: string;
    ERC20: string;
    RECOVERER: string;
};

export const PAYMASTER_ADDRESSES: PaymasterAddress = {
    DEPLOYER: '0x40C28929fBD647c229F446C21090aD83431FD24E',
    GASLESS: '0xCBf1367699d8704a320ADF06E23c294AC089270B',
    ERC20: '0x7a862CC27FAD666aB180F1708f8b18F7892FA761',
    RECOVERER: '0x6e2B2F85F42802637f3aA48C60fB5dFE28b6D2b7',
};
