/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetAllStats } from 'api';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult, UserDataDto } from 'api/types';

export const useGetAllStatsQuery = (): CustomQueryResult<
    Record<string, UserDataDto>
> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.ALL_STATS_DB],
        queryFn: async () => {
            const stats = await apiGetAllStats();
            return stats.data;
        },
        gcTime: Infinity,
        staleTime: Infinity,
    });

    return { data: data ?? {}, refetch: refetch, ...rest };
};
