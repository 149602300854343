/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createConfig, http } from 'wagmi';
import { zkSync } from 'wagmi/chains';

export const config = createConfig({
    chains: [zkSync],
    transports: {
        [zkSync.id]: http(),
    },
});
