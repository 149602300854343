/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { type ComputedDatum, ResponsiveBar } from '@nivo/bar';
import type { OrdinalColorScaleConfig } from '@nivo/colors';
import type { $MixedElement } from 'types';
import type { BarChartProps } from 'utils';

type BarChartComponentProps<K extends string> = {
    data: Array<Record<string, string | number>>;
    props: BarChartProps<K>;
};

export const BarChart = <K extends string>({
    data,
    props,
}: BarChartComponentProps<K>): $MixedElement => (
    <ResponsiveBar
        data={data}
        keys={props.keys}
        indexBy={props.indexBy}
        margin={
            props.legends
                ? { top: 30, right: 130, bottom: 140, left: 80 }
                : props.disableAxisLeft
                ? { top: 30, right: 20, bottom: 100, left: 20 }
                : { top: 30, right: 50, bottom: 140, left: 80 }
        }
        padding={0.3}
        valueScale={props.logScale || { type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={
            props.colors as OrdinalColorScaleConfig<
                ComputedDatum<Record<string, string | number>>
            >
        }
        valueFormat={props.valueFormat}
        borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
        }}
        theme={{
            axis: {
                ticks: {
                    text: {
                        fontSize: 10,
                    },
                },
            },
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -65,
            legend: props.axisBottomLegend,
            legendPosition: 'middle',
            legendOffset: 100,
            truncateTickAt: 0,
        }}
        axisLeft={
            props.disableAxisLeft
                ? null
                : {
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: props.axisLeftLegend,
                      legendPosition: 'middle',
                      legendOffset: -60,
                      truncateTickAt: 0,
                      format: props.valueFormat,
                  }
        }
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
        }}
        legends={props.legends}
        role="application"
        groupMode={props.groupMode}
        enableTotals={props.enableTotals}
        barAriaLabel={(e) => `Amount: ${e.formattedValue} for ${e.indexValue}`}
    />
);
