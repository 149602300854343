/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ACCESS_TOKEN, Paths } from 'const';
import { useAccessToken } from 'hooks/useAccessToken';
import { useClaveNavigation } from 'hooks/useClaveNavigation';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { type Account } from 'types';

export const useAccountDetails = (): Account | null => {
    const access = useAccessToken();
    const [details, setDetails] = useState<Account | null>(null);
    const navigate = useClaveNavigation();

    useEffect(() => {
        if (access != null) {
            try {
                const decoded = jwt_decode<Account>(access);
                setDetails(decoded);
            } catch (err) {
                localStorage.removeItem(ACCESS_TOKEN);
                navigate(Paths.AUTH);
                setDetails(null);
            }
        }
    }, [access]);

    return details;
};
