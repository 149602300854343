/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useState } from 'react';

export type ModalController = {
    isOpen: boolean;
    close: () => void;
    open: () => void;
};

export const useModalController = (initialValue = false): ModalController => {
    const [isOpen, setIsOpen] = useState(initialValue);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    return { open, close, isOpen };
};
