/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button, Col, Empty, List, Row, Spin, Typography } from 'antd';
import { useGetTranslationsMutation } from 'api/mutations/useGetTranslationsMutation';
import type { TranslationResponseDto } from 'api/types';
import { Layout, Sidebar } from 'components';
import { LocaleAddModal, LocaleEditorModal } from 'components';
import { useModalController } from 'hooks/useModalController';
import { useState } from 'react';
import type { $MixedElement } from 'types';

export const Locales = (): $MixedElement => {
    const [mutation, translations, setTranstlations] =
        useGetTranslationsMutation();
    const [selectedLocale, setSelectedLocale] =
        useState<TranslationResponseDto | null>(null);

    const modalController = useModalController();
    const addModalController = useModalController();

    return (
        <Row wrap={false}>
            {selectedLocale != null && (
                <LocaleEditorModal
                    mutation={mutation}
                    selectedLocale={selectedLocale}
                    setSelectedLocale={setSelectedLocale}
                    modalController={modalController}
                />
            )}
            <LocaleAddModal
                modalController={addModalController}
                setTranstlations={setTranstlations}
            />
            <Sidebar />
            <Layout>
                <Row className="items-center" justify="space-between">
                    <Typography.Title className="mb-0" level={1}>
                        Locales
                    </Typography.Title>
                    <Button type="primary" onClick={addModalController.open}>
                        Add new locale
                    </Button>
                </Row>

                <Typography.Text>
                    Modify update and manage locales
                </Typography.Text>

                <Col className="mt-4 justify-center w-[100%]">
                    {!mutation.isPending && translations.length === 0 && (
                        <Empty
                            description={
                                <Typography.Text>
                                    You don't have any locale data
                                </Typography.Text>
                            }
                        >
                            <Button>Add new locale</Button>
                        </Empty>
                    )}
                    {mutation.isPending && (
                        <Spin tip="Loading" size="small">
                            <div className="p-12 bg-gray-100 rounded-sm" />
                        </Spin>
                    )}
                    {translations.length > 0 && (
                        <List
                            size="large"
                            header={<div>Locales</div>}
                            bordered
                            dataSource={translations}
                            // eslint-disable-next-line
                            renderItem={(item) => (
                                <List.Item
                                    actions={[
                                        <Button
                                            onClick={(): void => {
                                                modalController.open();
                                                setSelectedLocale(item);
                                            }}
                                            type="default"
                                        >
                                            Edit
                                        </Button>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={item.language}
                                        description={'Version: ' + item.version}
                                    />
                                </List.Item>
                            )}
                        />
                    )}
                </Col>
            </Layout>
        </Row>
    );
};
