/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export enum StorageKeys {
    CLAVE_ACCOUNTS = 'CLAVE_ACCOUNTS',
    ALL_STATS_FILTERS = 'ALL_STATS_FILTERS',
}

export class Storage {
    public static set(key: StorageKeys, value: string): void {
        localStorage.setItem(key, value);
    }

    public static get(key: StorageKeys): string | null {
        return localStorage.getItem(key);
    }

    public static setJson<T>(key: StorageKeys, value: T): void {
        try {
            const stringified = JSON.stringify(value);
            localStorage.setItem(key, stringified);
        } catch (err) {
            console.log('FAILED TO SET JSON', key, err);
        }
    }

    public static getJson<T>(key: StorageKeys): T | null {
        try {
            const value = localStorage.getItem(key);
            if (!value) {
                return null;
            }
            return JSON.parse(value);
        } catch {
            return null;
        }
    }

    public static setArrayWithQuota<T>(
        key: StorageKeys,
        value: Array<T>,
    ): void {
        let index = 0;
        const quota = 1000;
        const maxIndex = 10;

        for (let i = 0; i < value.length; i += quota) {
            if (index > maxIndex) {
                console.error('Max index reached');
                break;
            }
            const chunk = value.slice(i, i + quota);
            this.setJson(`${key}_${index}` as StorageKeys, chunk);
            index++;
        }
    }

    public static getArrayWithQuota<T>(key: StorageKeys): Array<T> {
        let index = 0;
        const result: Array<T> = [];

        while (true) {
            const chunk = this.getJson<Array<T>>(
                `${key}_${index}` as StorageKeys,
            );
            if (chunk) {
                result.push(...chunk);
                index++;
            } else {
                break;
            }
        }

        return result;
    }

    public static clear(): void {
        localStorage.clear();
    }
}
