/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { bytesToDate, getDays } from './date';
import type { BarChartData, DataKeys } from './types';
import type { StatsQuery } from '.graphclient';

export function getDailyBarData(
    data: StatsQuery,
    key: DataKeys,
    dateRange: [number, number],
): Array<BarChartData<'count'>> {
    const days = getDays(data.days, dateRange);

    return days.map((day) => {
        return {
            date: bytesToDate(day.id),
            count: day[key],
        };
    });
}

export function getWeeklyBarData(
    data: StatsQuery,
    key: DataKeys,
): Array<BarChartData<'count'>> {
    return data.weeks.map((week) => {
        return {
            date: bytesToDate(week.id),
            count: week[key],
        };
    });
}

export function getMonthlyBarData(
    data: StatsQuery,
    key: DataKeys,
): Array<BarChartData<'count'>> {
    return data.months.map((month) => {
        return {
            date: bytesToDate(month.id),
            count: month[key],
        };
    });
}

export function getCumulativeBarData(
    data: StatsQuery,
    key: DataKeys,
): Array<BarChartData<'count'>> {
    let cumulativeCount = 0;
    return data.weeks.map((week) => {
        cumulativeCount += week[key];
        return {
            date: bytesToDate(week.id),
            count: cumulativeCount,
        };
    });
}
