/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ACCESS_TOKEN, Paths } from 'const';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useSetAuth } from 'store/AuthStore';
import { type Account } from 'types';
import { permissions } from 'utils';

interface Router {
    navigate: (path: string) => void;
}

export function useInitialAuth(router: Router): void {
    const setAuth = useSetAuth();

    useEffect(() => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (token == null) {
            router.navigate(Paths.AUTH);
            return;
        }
        const decoded = jwtDecode<Account>(token);
        permissions.set(decoded.email);
        const date = new Date();
        const tenMinutes = date.setTime(date.getTime() + 10 * 60 * 1000);

        if (decoded.exp > tenMinutes / 1000) {
            setAuth((auth) => ({
                ...auth,
                isAuth: true,
            }));
        } else {
            router.navigate(Paths.AUTH);
        }
    }, []);
}
