/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetTokenPrices } from 'api';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult, OdosPricesDto } from 'api/types';

const emptyStats: OdosPricesDto = {
    currencyId: '',
    tokenPrices: null,
};

export const useGetTokenPricesQuery = (): CustomQueryResult<OdosPricesDto> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.TOKEN_PRICES],
        queryFn: async () => {
            const stats = await apiGetTokenPrices();
            return stats.data;
        },
        gcTime: Infinity,
        staleTime: Infinity,
    });

    return { data: data ?? emptyStats, refetch: refetch, ...rest };
};
