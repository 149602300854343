/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const toDollar = (value: number): string => {
    return `$${value.toFixed(2)}`;
};

export const fromDollar = (value: string): number => {
    return parseFloat(value.slice(1));
};
