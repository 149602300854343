/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { BigNumber } from 'ethers';
import { useCallback } from 'react';
import { atom } from 'recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { SetterOrUpdater } from 'recoil';

export type PaymasterBalance = {
    deployer: BigNumber;
    gasless: BigNumber;
    erc20: BigNumber;
    recoverer: BigNumber;
};

export const PaymasterBalanceAtom = atom<PaymasterBalance>({
    default: {
        deployer: BigNumber.from(0),
        gasless: BigNumber.from(0),
        erc20: BigNumber.from(0),
        recoverer: BigNumber.from(0),
    },
    key: 'PaymasterBalance.Atom',
});

export const usePaymasterBalance = <T = PaymasterBalance | null>(): T => {
    return useRecoilValue(PaymasterBalanceAtom) as T;
};

export const usePaymasterBalanceNullSafe = (): PaymasterBalance => {
    return usePaymasterBalance<PaymasterBalance>();
};

export const useSetPaymasterBalance = (): SetterOrUpdater<PaymasterBalance> => {
    return useSetRecoilState(PaymasterBalanceAtom);
};

export const useUpdatePaymasterBalanceField = (): (<
    K extends keyof PaymasterBalance,
>(
    field: K,
    newValue: PaymasterBalance[K],
) => void) => {
    const setPaymasterBalance = useSetPaymasterBalance();

    return useCallback(
        <K extends keyof PaymasterBalance>(
            field: K,
            newValue: PaymasterBalance[K],
        ) => {
            setPaymasterBalance((current) => ({
                ...current,
                [field]: newValue,
            }));
        },
        [setPaymasterBalance],
    );
};
