/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Editor } from '@monaco-editor/react';
import { Button, Row, Typography } from 'antd';
import { Layout, Sidebar } from 'components';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';

export const DevtoolsEnvParser = (): $MixedElement => {
    const [json, setJson] = useState('');
    const [isDocker, setIsDocker] = useState(false);

    const parsedEnv = useMemo(() => {
        try {
            const parsed: Record<string, string> = JSON.parse(json);
            let str = '';

            Object.keys(parsed).forEach((key) => {
                str += `${key}="${parsed[key]}"\n`;
            });

            return str;
        } catch {
            return 'Invalid json';
        }
    }, [json]);

    const parsedDocker = useMemo(() => {
        try {
            const parsed: Record<string, string> = JSON.parse(json);
            let str = '';

            Object.keys(parsed).forEach((key) => {
                str += `            - ${key}=${parsed[key]}\n`;
            });

            const base = `version: '3.8'
services:
    api:
        image: asgarovfarhad/dev-clave-api:1.0.29
        container_name: dev-clave-backend-api
        build: ./
        ports:
            - 8000:8000
        restart: unless-stopped
        networks:
            - api-network
        environment:
${str}
        platform: linux/amd64

networks:
    api-network:
`;

            return base;
        } catch {
            return 'Invalid json';
        }
    }, [json]);

    const editorOptions = {
        height: '75vh',
        width: '100%',
        options: {
            fontSize: 14,
        },
    };

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <Typography.Title level={1}>JSON - ENV Parser</Typography.Title>
                <Row>
                    <div className="w-[50%] flex-col">
                        <Typography.Title level={4} className="mb-4">
                            JSON
                        </Typography.Title>
                        <Editor
                            {...editorOptions}
                            value={json}
                            language="json"
                            onChange={(e): void => {
                                if (e != null) {
                                    setJson(e);
                                }
                            }}
                        />
                    </div>
                    <div className="w-[50%] flex-col">
                        <Typography.Title level={4} className="mb-4">
                            <Button
                                onClick={(): void => setIsDocker(false)}
                                type={!isDocker ? 'primary' : 'default'}
                                className="mr-2"
                            >
                                Env
                            </Button>
                            <Button
                                onClick={(): void => setIsDocker(true)}
                                type={isDocker ? 'primary' : 'default'}
                            >
                                Docker
                            </Button>
                        </Typography.Title>
                        <Editor
                            {...editorOptions}
                            value={isDocker ? parsedDocker : parsedEnv}
                            onChange={(): void => undefined}
                            language={isDocker ? 'yaml' : 'txt'}
                            options={{
                                ...editorOptions.options,
                                readOnly: true,
                            }}
                        />
                    </div>
                </Row>
            </Layout>
        </Row>
    );
};
