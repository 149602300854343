/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Segmented, Spin } from 'antd';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    ChartInterval,
    FEE_PCT,
    type LineChartProps,
    addressToToken,
    bytesToDate,
    formatUnits,
} from 'utils';

import { LineChart } from '../charts/LineChart';
import type { StatsQuery } from '.graphclient';

export const RevenuePerActiveUser = ({
    data,
    isLoading,
    tokenPrices,
}: {
    data: StatsQuery;
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
}): $MixedElement => {
    const [chartInterval, setChartInterval] = useState<ChartInterval>(
        ChartInterval.Weekly,
    );

    const chartData = useMemo(() => {
        if (tokenPrices == null) {
            return { daily: [], weekly: [], monthly: [], cumulative: [] };
        }

        const weeklyData = data.weeks.map((week) => {
            let total = 0;
            week.swappedTo.forEach((swappedTo) => {
                const tokenAddress = (swappedTo.erc20 as string).toLowerCase();

                const token = addressToToken[tokenAddress];
                if (!token) {
                    console.log('Token not found', tokenAddress);
                    return;
                }
                const decimals = token.decimals;

                const tokenPrice = tokenPrices[tokenAddress];
                if (!tokenPrice) {
                    console.log('Token price not found', tokenAddress);
                    return;
                }

                const amountOut = formatUnits(swappedTo.amount, decimals);
                const fee = amountOut * FEE_PCT;

                const usdValue = fee * tokenPrice;
                total += usdValue;
            });

            const activeAccounts = week.activeAccounts;
            return {
                x: bytesToDate(week.id),
                y: Number((total / activeAccounts).toFixed(2)),
            };
        });

        const monthlyData = data.months.map((month) => {
            let total = 0;
            month.swappedTo.forEach((swappedTo) => {
                const tokenAddress = (swappedTo.erc20 as string).toLowerCase();

                const token = addressToToken[tokenAddress];
                if (!token) {
                    console.log('Token not found', tokenAddress);
                    return;
                }
                const decimals = token.decimals;

                const tokenPrice = tokenPrices[tokenAddress];
                if (!tokenPrice) {
                    console.log('Token price not found', tokenAddress);
                    return;
                }

                const amountOut = formatUnits(swappedTo.amount, decimals);
                const fee = amountOut * FEE_PCT;

                const usdValue = fee * tokenPrice;
                total += usdValue;
            });

            const activeAccounts = month.activeAccounts;
            return {
                x: bytesToDate(month.id),
                y: Number((total / activeAccounts).toFixed(2)),
            };
        });

        return {
            [ChartInterval.Daily]: [],
            [ChartInterval.Weekly]: [
                {
                    id: 'Weekly Revenue per Active User',
                    data: weeklyData,
                },
            ],
            [ChartInterval.Monthly]: [
                {
                    id: 'Monthly Revenue per Active User',
                    data: monthlyData,
                },
            ],
            [ChartInterval.Cumulative]: [],
        };
    }, [data, tokenPrices]);

    const lineProps: LineChartProps = {
        axisBottomLegend: 'Date',
        axisLeftLegend: 'Count',
        axisLeftTickValues: 15,
        axisLeftFormat: undefined,
        yFormat: undefined,
        margin: { top: 40, right: 50, bottom: 100, left: 100 },
    };

    if (isLoading || tokenPrices == null) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }
    return (
        <div className="w-[100%] min-h-[80vh]">
            <Segmented
                options={[ChartInterval.Weekly, ChartInterval.Monthly]}
                onChange={(value): void => {
                    setChartInterval(value as ChartInterval);
                }}
                style={{ marginBottom: 12, marginLeft: 6 }}
            />
            <LineChart data={chartData[chartInterval]} props={lineProps} />
        </div>
    );
};
