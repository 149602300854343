/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Input, Pagination, Row, Spin, Table, Typography } from 'antd';
import { useGetWaitlistsMutation } from 'api/mutations/useGetWaitlistsMutation';
import { Layout, Sidebar } from 'components';
import { useState } from 'react';
import type { $MixedElement } from 'types';

import { useDebounce } from '../hooks/useDebounce';

export const Waitlist = (): $MixedElement => {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const debouncedSearch = useDebounce(search);

    const [mutation, waitlists] = useGetWaitlistsMutation({
        debouncedSearch,
        page,
    });

    const dataSource = waitlists.data;

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
        },
    ];

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <Row className="items-center" justify="space-between">
                    <Typography.Title className="mb-0" level={1}>
                        Waitlists
                    </Typography.Title>
                    <Row className="items-center">
                        <Typography.Text>
                            Count: {waitlists.count}
                        </Typography.Text>
                        <Input
                            size="large"
                            placeholder="Search"
                            className="w-[200px] ml-2"
                            value={search}
                            onChange={(e): void => {
                                setSearch(e.target.value);
                                setPage(0);
                            }}
                        />
                    </Row>
                </Row>
                {mutation.isPending && (
                    <Spin tip="Loading" size="small">
                        <div className="p-12 bg-gray-100 rounded-sm" />
                    </Spin>
                )}
                {!mutation.isPending && waitlists.data.length > 0 && (
                    <>
                        <div className="max-h-[80vh] overflow-auto">
                            <Table
                                pagination={false}
                                dataSource={dataSource}
                                columns={columns}
                            />
                        </div>
                        <Pagination
                            onChange={(e): void => {
                                setPage(e - 1);
                            }}
                            pageSize={50}
                            showSizeChanger={false}
                            className="mt-5 mx-auto"
                            current={page + 1}
                            defaultCurrent={1}
                            total={waitlists.count}
                        />
                    </>
                )}
            </Layout>
        </Row>
    );
};
