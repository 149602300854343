/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiPostGatekeeper } from 'api';

import type { CustomMutationResult, Gatekeeper } from '../types';

export const useCreateGatekeeperMutation = (): CustomMutationResult<
    Gatekeeper | null,
    Partial<Gatekeeper>
> => {
    const mutation = useMutation({
        mutationFn: async (dto: Partial<Gatekeeper>) => {
            try {
                const response = await apiPostGatekeeper(dto);
                return response.data;
            } catch {
                return null;
            }
        },
    });

    return mutation;
};
