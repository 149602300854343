/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export enum Permission {
    WAITLIST_READ = 'WAITLIST_READ',
    PUSH_NOTIFICATIONS_ALL = 'PUSH_NOTIFICATIONS_ALL',
    DASHBOARD_ALL = 'DASHBOARD_ALL',
    DASHBOARD_V2_ALL = 'DASHBOARD_V2_ALL',
    WAITLIST_CODE_ALL = 'WAITLIST_CODE_ALL',
    RAFFLES_ALL = 'RAFFLES_ALL',
    LOCALES_ALL = 'LOCALES_ALL',
    GATEKEEPER_ALL = 'GATEKEEPER_ALL',
    DEV = 'DEV',
}

export class Permissions {
    private email: string | null;

    constructor() {
        this.email = null;
    }

    public set = (email: string): void => {
        this.email = email;
    };

    private readonly permissions: Record<string, Array<Permission>> = {
        'farhad@clave.team': [Permission.DEV],
        'rafi@clave.team': [Permission.DEV],
        'baki@clave.team': [Permission.DEV],
        'ulas@clave.team': [Permission.DEV],
        'tahir@clave.team': [Permission.DEV],
        'hamza@clave.team': [Permission.DEV],
        'alim@clave.team': [Permission.DEV],

        'zeynep@clave.team': [],
        'dogan@clave.team': [Permission.RAFFLES_ALL],
        'mami@clave.team': [Permission.RAFFLES_ALL],
        'nurlan@clave.team': [Permission.RAFFLES_ALL],
        'ismayil@clave.team': [Permission.RAFFLES_ALL],
        'qnb@clave.team': [
            Permission.DASHBOARD_ALL,
            Permission.DASHBOARD_V2_ALL,
        ],
    };

    public hasPermission = (permission: Permission | undefined): boolean => {
        if (permission == null) {
            return true;
        }

        if (this.email == null) {
            return false;
        }

        if (this.permissions[this.email].includes(Permission.DEV)) {
            return true;
        }

        return this.permissions[this.email].includes(permission);
    };

    public reset(): void {
        this.email = null;
    }
}

export const permissions = new Permissions();
