/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Input, Modal } from 'antd';
import { useUpdateTranslationMutation } from 'api/mutations/useUpdateTranslationMutation';
import type { TranslationResponseDto } from 'api/types';
import type { ModalController } from 'hooks/useModalController';
import { type Dispatch, type SetStateAction, useState } from 'react';
import type { $MixedElement } from 'types';

type Props = {
    modalController: ModalController;
    setTranstlations: Dispatch<SetStateAction<Array<TranslationResponseDto>>>;
};

export const LocaleAddModal = ({
    modalController,
    setTranstlations,
}: Props): $MixedElement => {
    const [language, setLanguage] = useState<string>('');

    const buildPayload = (): TranslationResponseDto => {
        return {
            language,
            version: '1',
            data: {},
        };
    };

    const mutation = useUpdateTranslationMutation('add', () => {
        modalController.close();
        setTranstlations(
            (translations): Array<TranslationResponseDto> => [
                ...translations,
                buildPayload(),
            ],
        );
    });

    const onCancel = (): void => {
        setLanguage('');
        modalController.close();
    };

    const onSave = (): void => {
        mutation.mutate(buildPayload());
    };

    return (
        <>
            <Modal
                title={`Add new locale`}
                centered
                maskClosable={false}
                okText={'Save'}
                open={modalController.isOpen}
                onOk={onSave}
                okButtonProps={{
                    loading: mutation.isPending,
                }}
                onCancel={onCancel}
                width={512}
            >
                <Input
                    value={language}
                    onChange={(e): void => {
                        setLanguage(e.target.value);
                    }}
                    placeholder="Language key"
                />
            </Modal>
        </>
    );
};
